import { css } from 'emotion';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import {
    Badge,
    Button,
    Card,
    DragHandle,
    Flex,
    FormControl,
    Heading,
    Modal,
    Paragraph,
    Skeleton,
    Stack,
    Switch,
    Text,
    TextInput,
} from '@contentful/f36-components';
import { EditIcon, ToggleIcon } from '@contentful/f36-icons';
import { DndContext } from '@dnd-kit/core';
import {
    arrayMove,
    SortableContext,
    useSortable,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { ENV } from '../../../environments';
import { useVetdeskMiddleware } from '../../../hooks';
import { Species } from '../../../models';

const API_URL_SYNC = `${ENV.middlewareBaseUrl}/api/v2/admin/pms/sync/species`;
const API_URL = `${ENV.middlewareBaseUrl}/api/v2/admin/species`;

export const SpeciesTypes: React.FC = () => {
    const styles = {
        card: css({
            // This lets us change z-index when dragging
            position: 'relative',
        }),
        dragHandle: css({
            alignSelf: 'stretch',
        }),
    };
    const [draggableItems, setDraggableItems] = useState<Species[] | undefined>(
        undefined
    );
    const [editItem, setEditItem] = useState(null);

    const { runRequest } = useVetdeskMiddleware();

    const fetchTypes = () => {
        setDraggableItems(undefined); // to display skeleton when fetching

        runRequest('GET', `${API_URL}`).then((res) => {
            const response = res as Species[];
            setDraggableItems(response);
        });
    };

    const syncFromPMS = () => {
        setDraggableItems(undefined); // to display skeleton when fetching

        runRequest('POST', `${API_URL_SYNC}`).then((res) => {
            const response = res as Species[];
            setDraggableItems(response);
        });
    };

    const {
        handleSubmit,
        formState: { errors },
        reset,
        getValues,
        control,
    } = useForm();

    useEffect(() => {
        fetchTypes();
    }, []);

    function SortableCard({ id, value }: any) {
        const {
            attributes,
            listeners,
            setNodeRef,
            transform,
            transition,
            active,
        } = useSortable({
            id,
        });
        const zIndex = active && active.id === id ? 1 : 0;
        const style = {
            transform: CSS.Translate.toString(transform),
            transition,
            zIndex,
        };

        return (
            <Card
                className={styles.card}
                dragHandleRender={() => (
                    <DragHandle
                        as="button"
                        className={styles.dragHandle}
                        label="Move card"
                        {...attributes}
                        {...listeners}
                    />
                )}
                padding="none"
                withDragHandle
                ref={setNodeRef}
                style={style}
            >
                <Flex
                    justifyContent="space-between"
                    alignItems="center"
                    padding="spacingS"
                >
                    <span>
                        <b>{value.name}</b>
                    </span>{' '}
                    <Flex
                        justifyContent="space-between"
                        alignItems="center"
                        gap="spacingXl"
                    >
                        {value.isActive ? (
                            <Badge variant="positive">ACTIVE</Badge>
                        ) : (
                            <Badge variant="negative">INACTIVE</Badge>
                        )}
                        <Button
                            onClick={() => {
                                reset(value); // update the useForm with the selected item
                                setEditItem(value);
                            }}
                            startIcon={<EditIcon />}
                            size="small"
                        >
                            Edit
                        </Button>
                    </Flex>
                </Flex>
            </Card>
        );
    }

    const updateSort = async (data: Species[]) => {
        await runRequest('PUT', API_URL, data).then(
            (res) => {
                fetchTypes();
            },
            (error) => console.warn('Oops', error)
        );
    };

    const handleDragEnd = (event: { active: any; over: any }) => {
        const { active, over } = event;

        if (active && over && active.id !== over.id) {
            setDraggableItems((items) => {
                if (items) {
                    const oldIndex = items.findIndex(
                        (item) => item.id === active.id
                    );
                    const newIndex = items.findIndex(
                        (item) => item.id === over.id
                    );
                    const updatedItems = arrayMove(items, oldIndex, newIndex);
                    updateSort(updatedItems);
                    return updatedItems;
                }
            });
        }
    };

    const onSubmit = async (data: any) => {
        console.log('submitted data', data);
        await runRequest('PUT', `${API_URL}/${data.id}`, data).then(
            (res) => {
                fetchTypes();
                setEditItem(null);
            },
            (error) => console.warn('Oops', error)
        );
    };

    const renderEditModel = () => {
        return (
            <Modal
                size="800px"
                onClose={() => setEditItem(null)}
                isShown={editItem != null}
                shouldCloseOnOverlayClick={false}
            >
                {() => (
                    <>
                        <Modal.Header
                            title="Edit"
                            onClose={() => setEditItem(null)}
                        />
                        <Modal.Content>
                            <FormControl>
                                <FormControl.Label>Status</FormControl.Label>
                                <Controller
                                    name="isActive"
                                    control={control}
                                    render={({ field }) => (
                                        <Switch
                                            isChecked={field.value}
                                            onChange={field.onChange}
                                        >
                                            {field.value == true ? 'On' : 'Off'}
                                        </Switch>
                                    )}
                                />
                                <Paragraph>
                                    Toggle on/off to deactivate or activate
                                    without losing any information.
                                </Paragraph>
                            </FormControl>
                            <FormControl>
                                <FormControl.Label>
                                    Species display name{' '}
                                    <Text fontColor="red500">*</Text>
                                </FormControl.Label>
                                <Controller
                                    name="name"
                                    control={control}
                                    rules={{
                                        required: 'This field is required',
                                    }}
                                    render={({ field }) => (
                                        <TextInput
                                            {...field}
                                            id="name"
                                            placeholder="Enter text"
                                        />
                                    )}
                                />
                                <Paragraph>
                                    PMS name: {getValues('pmsName')}
                                </Paragraph>
                            </FormControl>
                        </Modal.Content>
                        <Modal.Controls>
                            <Button
                                size="small"
                                variant="transparent"
                                onClick={() => {
                                    setEditItem(null);
                                    // remove();
                                    // reset();
                                }}
                            >
                                Close
                            </Button>
                            <Button
                                size="small"
                                variant="positive"
                                onClick={() => handleSubmit(onSubmit)()}
                            >
                                Save
                            </Button>
                        </Modal.Controls>
                    </>
                )}
            </Modal>
        );
    };

    return (
        <>
            <Stack justifyContent="space-between">
                <div>
                    <Heading>Species</Heading>
                    <Paragraph style={{ marginTop: 10 }}>
                        Add or remove species in the existing list by editing
                        names, and use drag-and-drop to arrange their order in
                        the booking form.
                    </Paragraph>
                </div>

                <Button
                    startIcon={<ToggleIcon />}
                    variant="secondary"
                    size="small"
                    onClick={syncFromPMS}
                >
                    Refresh from PMS
                </Button>
            </Stack>

            {draggableItems ? (
                <DndContext onDragEnd={handleDragEnd}>
                    <SortableContext
                        items={draggableItems.map((x) => x.id)}
                        strategy={verticalListSortingStrategy}
                    >
                        <Flex flexDirection="column" gap="spacingS">
                            {draggableItems.map((item) => (
                                <SortableCard
                                    key={item.id}
                                    id={item.id}
                                    value={item}
                                />
                            ))}
                        </Flex>
                    </SortableContext>
                </DndContext>
            ) : (
                <Skeleton.Container>
                    <Skeleton.BodyText numberOfLines={4} />
                </Skeleton.Container>
            )}
            {renderEditModel()}
        </>
    );
};
