import React, { useEffect, useState } from 'react';

import {
    Card,
    Flex,
    Heading,
    Skeleton,
    Stack,
    Subheading,
} from '@contentful/f36-components';

import { useVetdeskMiddleware } from '../../hooks';
import { navigateHelper, NavigationLocation } from '../../locations/page';
import {
    DashboardStats,
    DashboardUrl,
    DashboardWidgetConfig,
} from '../../models/dashboard';
import { DateRangeEnum, DateRangePicker } from '../date-range-picker';

export const DashboardStatsPage: React.FC<DashboardWidgetConfig> = (props) => {
    const { runRequest } = useVetdeskMiddleware<any>();

    const [statsLoading, setStatsLoading] = useState(true);
    const [stats, setStats] = useState<DashboardStats | undefined>(undefined);
    const [statsRange, SetStatsRange] = useState<DateRangeEnum>(
        props.dateRange
    );

    // stats
    useEffect(() => {
        setStatsLoading(true);
        runRequest('GET', `${DashboardUrl}/stats/${statsRange}`).then((res) => {
            setStatsLoading(false);
            setStats(res as DashboardStats);
        });
    }, [statsRange]);

    return (
        <>
            <Stack justifyContent="space-between">
                <Subheading>Engagement</Subheading>
                <DateRangePicker
                    value={statsRange}
                    onSelect={(v) => SetStatsRange(v as DateRangeEnum)}
                />
            </Stack>
            {statsLoading ? (
                <Skeleton.Container>
                    <Skeleton.BodyText numberOfLines={4} />
                </Skeleton.Container>
            ) : (
                <Flex
                    flexDirection="row"
                    gap="spacingS"
                    style={{ marginTop: 25 }}
                >
                    <Card
                        onClick={() =>
                            navigateHelper(NavigationLocation.customers)
                        }
                    >
                        <Subheading>New Customers</Subheading>
                        <Heading>{stats && stats.customers}</Heading>
                    </Card>
                    <Card
                        onClick={() =>
                            navigateHelper(NavigationLocation.orders)
                        }
                    >
                        <Subheading>Orders</Subheading>
                        <Heading>{stats && stats.orders}</Heading>
                    </Card>

                    <Card
                        onClick={() =>
                            navigateHelper(NavigationLocation.subscriptions)
                        }
                    >
                        <Subheading>Subscriptions</Subheading>
                        <Heading>{stats && stats.subscriptions}</Heading>
                    </Card>

                    <Card
                        onClick={() =>
                            navigateHelper(NavigationLocation.photoCompetitions)
                        }
                    >
                        <Subheading>Photo contest entries</Subheading>
                        <Heading>{stats && stats.leaderboardEntries}</Heading>
                    </Card>
                </Flex>
            )}
        </>
    );
};
