import React, { useEffect, useState } from 'react';

import { Card, Flex, Select, Text } from '@contentful/f36-components';
import { ClockIcon } from '@contentful/f36-icons';

const hourArray = [
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
];
const minuteArray = ['00', '15', '30', '45'];

export interface TimePickerProps {
    /**
     * The selected time value in a string format (e.g., "17:30").
     */
    value?: string;
    /**
     * Callback function to handle changes in the selected time.
     * The `data` parameter contains the updated time in string format (e.g., "17:30").
     */
    handleChange?: (data: string) => void;
    /**
     * Determines if the time picker should be disabled.
     */
    isDisabled?: boolean;
    /**
     * Array of hour options available for selection.
     * Each value is a string representing an hour (e.g., "08", "09", "10").
     * Default: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'].
     */
    hourOptions?: string[];
    /**
     * Array of minute options available for selection.
     * Each value is a string representing minutes (e.g., "00", "15", "30", "45").
     * Default: ['00', '15', '30', '45'].
     */
    minuteOptions?: string[];
}

export const TimePicker: React.FC<TimePickerProps> = ({
    value,
    handleChange,
    isDisabled = false,
    hourOptions = hourArray,
    minuteOptions = minuteArray,
}) => {
    // Component states
    const [hour, setHour] = useState(
        value ? convertTimeToFields(value).hour : ''
    );
    const [minute, setMinute] = useState(
        value ? convertTimeToFields(value).minute : ''
    );
    const [meridiem, setMeridiem] = useState(
        value ? convertTimeToFields(value).meridiem : ''
    );

    useEffect(() => {
        if (hour && minute && meridiem && handleChange) {
            // Adjust hours to 24-hour format based on AM/PM
            let adjustedHours = parseInt(hour, 10);

            if (meridiem === 'PM' && adjustedHours !== 12) {
                adjustedHours += 12; // Convert to PM hours, except 12 PM
            } else if (meridiem === 'AM' && adjustedHours === 12) {
                adjustedHours = 0; // Convert 12 AM to 00 hours
            }

            handleChange(
                `${adjustedHours.toString().padStart(2, '0')}:${minute}`
            );
        }
    }, [hour, minute, meridiem]);

    return (
        <Card style={{ padding: 0 }}>
            <Flex
                flexDirection="row"
                gap="spacing2Xs"
                justifyContent="space-between"
            >
                <ClockIcon
                    size="medium"
                    variant="secondary"
                    style={{ margin: 7 }}
                />
                <Select
                    value={hour}
                    onChange={(e) => setHour(e.target.value)}
                    isDisabled={isDisabled}
                >
                    <Select.Option value="" isDisabled>
                        Select...
                    </Select.Option>
                    {hourOptions.map((hour, i) => (
                        <Select.Option key={i} value={hour}>
                            {hour}
                        </Select.Option>
                    ))}
                </Select>
                <Text
                    fontWeight="fontWeightDemiBold"
                    marginTop="spacingXs"
                    marginLeft="none"
                    marginRight="none"
                    fontSize="fontSizeXl"
                >
                    :
                </Text>
                <Select
                    value={minute}
                    onChange={(e) => setMinute(e.target.value)}
                    isDisabled={isDisabled}
                >
                    <Select.Option value="" isDisabled>
                        Select...
                    </Select.Option>
                    {minuteOptions.map((minute, i) => (
                        <Select.Option key={i} value={minute}>
                            {minute}
                        </Select.Option>
                    ))}
                </Select>
                <Select
                    value={meridiem}
                    onChange={(e) => setMeridiem(e.target.value)}
                    isDisabled={isDisabled}
                >
                    <Select.Option value="" isDisabled>
                        Select...
                    </Select.Option>
                    <Select.Option value="AM">AM</Select.Option>
                    <Select.Option value="PM">PM</Select.Option>
                </Select>
            </Flex>
        </Card>
    );
};

const convertTimeToFields = (time: string) => {
    const [hourStr, minute] = time.split(':');
    let hour = parseInt(hourStr, 10);
    let meridiem = 'AM';

    if (hour >= 12) {
        meridiem = 'PM';
        hour = hour > 12 ? hour - 12 : hour; // Convert 13-23 to 1-11, leave 12 as it is
    } else if (hour === 0) {
        hour = 12; // Midnight edge case
    }

    return {
        hour: hour.toString().padStart(2, '0'), // Ensures two digits
        minute,
        meridiem,
    };
};
