import React, { useEffect, useState } from 'react';

import {
    Card,
    Datepicker,
    Flex,
    Select,
    Stack,
    Text,
} from '@contentful/f36-components';
import { ClockIcon } from '@contentful/f36-icons';

import { getTimeFromDate } from '../utilities/date-helper';

const hoursArray = [
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
];

const minuteArray = ['00', '15', '30', '45'];

export interface DatetimePickerProps {
    value?: Date;
    fromDate?: Date;
    onSelect: (date: Date) => void;
    isDisabled?: boolean;
}

type TimeTuple = [hh: string, mm: string, ampm: string];

export const DatetimePicker: React.FC<DatetimePickerProps> = ({
    value,
    fromDate,
    onSelect: onSelect,
    isDisabled = false,
}) => {
    const [selectedDate, setSelectedDate] = useState<Date>(
        value ? value : new Date()
    );

    const splitTime = (value: Date): TimeTuple => {
        const timeString = getTimeFromDate(value);
        const [time, period] = timeString.split(' ');
        const [hrs, mins] = time.split(':');
        return [hrs, mins, period];
    };

    const [hours, setHours] = useState(value ? splitTime(value)[0] : '08');
    const [minutes, setMinutes] = useState(value ? splitTime(value)[1] : '30');
    const [ampm, setAmPm] = useState(value ? splitTime(value)[2] : 'AM');

    useEffect(() => {
        // concat date and time here
        const date = new Date(selectedDate);

        // Adjust hours for PM if necessary
        let adjustedHours = parseInt(hours);
        if (ampm === 'PM' && hours != '12') {
            adjustedHours += 12;
        }

        // Set the time components on the date
        date.setHours(adjustedHours, Number(minutes), 0, 0);

        onSelect && onSelect(date);
    }, [selectedDate, hours, minutes, ampm]);

    return (
        <Stack flexDirection="row" alignItems="start" style={{ zIndex: 0 }}>
            <Datepicker
                inputProps={{ isDisabled: isDisabled }}
                disabled={isDisabled}
                style={{ width: '50%' }}
                selected={selectedDate ? new Date(selectedDate) : new Date()}
                onSelect={(val) => {
                    val && setSelectedDate(val);
                }}
                fromDate={fromDate ? new Date(fromDate) : undefined}
            />

            <Card style={{ padding: 0, width: '50%' }}>
                <Flex
                    flexDirection="row"
                    gap="spacing2Xs"
                    justifyContent="space-between"
                >
                    <ClockIcon
                        size="medium"
                        variant="secondary"
                        style={{ margin: 7 }}
                    />
                    <Select
                        isDisabled={isDisabled}
                        value={hours}
                        onChange={(e) => {
                            setHours(e.target.value);
                        }}
                    >
                        <Select.Option value="" isDisabled>
                            Select...
                        </Select.Option>
                        {hoursArray.map((hour, i) => (
                            <Select.Option key={i} value={hour}>
                                {hour}
                            </Select.Option>
                        ))}
                    </Select>
                    <Text
                        fontWeight="fontWeightDemiBold"
                        marginTop="spacingXs"
                        marginLeft="none"
                        marginRight="none"
                        fontSize="fontSizeXl"
                    >
                        :
                    </Text>
                    <Select
                        isDisabled={isDisabled}
                        value={minutes}
                        onChange={(e) => {
                            setMinutes(e.target.value);
                        }}
                    >
                        <Select.Option value="" isDisabled>
                            Select...
                        </Select.Option>
                        {minuteArray.map((minute, i) => (
                            <Select.Option key={i} value={minute}>
                                {minute}
                            </Select.Option>
                        ))}
                    </Select>
                    <Select
                        isDisabled={isDisabled}
                        value={ampm}
                        onChange={(e) => {
                            setAmPm(e.target.value);
                        }}
                    >
                        <Select.Option value="" isDisabled>
                            Select...
                        </Select.Option>
                        <Select.Option value="AM">AM</Select.Option>
                        <Select.Option value="PM">PM</Select.Option>
                    </Select>
                </Flex>
            </Card>
        </Stack>
    );
};
