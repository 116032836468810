import React, { useEffect, useState } from 'react';

import {
    Badge,
    Button,
    Card,
    Flex,
    Modal,
    Note,
    Spinner,
    Stack,
    Subheading,
    Tabs,
    Text,
} from '@contentful/f36-components';

import { ENV } from '../../environments';
import { useVetdeskMiddleware } from '../../hooks';
import { ApiEntityStatus, Order, Subscription } from '../../models';
import { Appointment } from '../../models/appointments';
import {
    CustomerDetail,
    CustomerDetailModalProps,
} from '../../models/customers';
import { calculateAgeFromDate } from '../../utilities/date-helper';
import {
    GetAppointmentColumns,
    ORDER_COLUMNS,
    SUBSCRIPTION_COLUMNS,
} from '../../utilities/table-columns';
import { TableList } from '../table-list';
import PMSLinkForm from './pms-link-form';

const API_URL = `${ENV.middlewareBaseUrl}/api/v1/admin/customer`;

export const CustomerDetailPage: React.FC<CustomerDetailModalProps> = (
    props
) => {
    const { formData, setVisibility, isVisible } = props;
    const { fetchEntity } = useVetdeskMiddleware();

    const [loadingDetail, setLoadingDetail] = useState(false);
    const [detail, setDetail] = useState<CustomerDetail | undefined>(undefined);
    const [currentTab, setCurrentTab] = useState('first');

    const [showPMSLinkForm, setShowPMSLinkForm] = useState(false);

    const fetchCustomerDetail = (id: string) => {
        setLoadingDetail(true);
        fetchEntity(`${API_URL}/${id}`).then((res) => {
            setDetail(res as CustomerDetail);
            setLoadingDetail(false);
        });
    };

    useEffect(() => {
        formData && formData.id && fetchCustomerDetail(formData.id);
    }, [formData]);

    const renderDetail = () => {
        return (
            <Modal.Content>
                {loadingDetail ? (
                    <Stack flexDirection="column">
                        <Spinner size="large" />
                    </Stack>
                ) : (
                    <>
                        {detail && (
                            <Tabs
                                defaultTab="first"
                                currentTab={currentTab}
                                onTabChange={setCurrentTab}
                                style={{ minHeight: 500 }}
                            >
                                <Tabs.List>
                                    <Tabs.Tab panelId="first">Info</Tabs.Tab>
                                    <Tabs.Tab panelId="second">Orders</Tabs.Tab>
                                    <Tabs.Tab panelId="third">
                                        Subscriptions
                                    </Tabs.Tab>
                                    <Tabs.Tab
                                        panelId="pms"
                                        isDisabled={
                                            !detail?.customer?.authIdentifier
                                        }
                                    >
                                        PMS Linked Account
                                    </Tabs.Tab>
                                </Tabs.List>
                                <Tabs.Panel id="first">
                                    {renderInformation()}
                                </Tabs.Panel>
                                <Tabs.Panel id="second">
                                    {renderOrders()}
                                </Tabs.Panel>
                                <Tabs.Panel id="third">
                                    {renderSubscriptions()}
                                </Tabs.Panel>
                                <Tabs.Panel id="pms">
                                    {renderPMSInfo()}
                                </Tabs.Panel>
                            </Tabs>
                        )}
                    </>
                )}
            </Modal.Content>
        );
    };

    const renderInformation = () => {
        if (!detail) return;
        const customerDetail = detail.customer;
        return (
            <Flex className="spacingTop" flexDirection="column">
                <Card>
                    <Flex
                        className="spacingTop"
                        flexDirection="column"
                        style={{ marginBottom: 25 }}
                    >
                        <Text>
                            <strong> Name:</strong> {customerDetail.firstName}{' '}
                            {customerDetail.lastName}
                        </Text>
                        <Text>
                            <strong> Stripe #:</strong>{' '}
                            {customerDetail.stripeIdentifier}
                        </Text>
                        <Text>
                            <strong> Email:</strong> {customerDetail.email}
                        </Text>
                        <Text>
                            <strong> Phone:</strong> {customerDetail.phone}
                        </Text>
                        <Text>
                            <strong> Registered From:</strong>{' '}
                            {customerDetail.registeredFrom}
                        </Text>
                        <Text>
                            {' '}
                            <strong> Status:</strong>{' '}
                            <Badge
                                variant={
                                    customerDetail.statusId ==
                                    ApiEntityStatus.ACTIVE
                                        ? 'positive'
                                        : 'negative'
                                }
                            >
                                {customerDetail.statusId}
                            </Badge>
                        </Text>
                    </Flex>
                </Card>

                <Subheading marginBottom="none" marginTop="spacingL">
                    Pet/s
                </Subheading>

                <Flex className="space-between" gap="spacingL">
                    {detail?.pets.map((pet) => {
                        return (
                            <Card key={pet.id}>
                                <div>
                                    <b>Name:</b> {pet.name}
                                </div>
                                <div>
                                    <b>Age:</b>{' '}
                                    {pet.dateOfBirth &&
                                        calculateAgeFromDate(pet.dateOfBirth)}
                                </div>
                                <div>
                                    <b>Breed:</b> {pet.breed}
                                </div>
                                <div>
                                    <b>Type:</b> {pet.type}
                                </div>
                                <div>
                                    <b>Gender:</b> {pet.gender}
                                </div>

                                <div>
                                    <b>Desexed:</b>{' '}
                                    {pet.desexed ? 'true' : 'false'}
                                </div>
                                <div>
                                    <b>Weight:</b> {pet.weight} Kg
                                </div>
                                <div>
                                    <b>Microchip:</b>{' '}
                                    {pet.microchipNumber
                                        ? `${pet.microchipNumber} at ${pet.microchipLocation}`
                                        : ''}
                                </div>
                            </Card>
                        );
                    })}
                </Flex>
                <Subheading marginBottom="none" marginTop="spacingL">
                    Appointment/s
                </Subheading>
                <TableList<Appointment>
                    columns={GetAppointmentColumns}
                    items={detail?.appointments}
                />
            </Flex>
        );
    };

    const renderOrders = () => {
        return (
            <Flex className="spacingTop" flexDirection="column">
                <TableList<Order>
                    columns={ORDER_COLUMNS}
                    items={detail?.orders}
                />
            </Flex>
        );
    };

    const renderSubscriptions = () => {
        return (
            <Flex className="spacingTop" flexDirection="column">
                <TableList<Subscription>
                    columns={SUBSCRIPTION_COLUMNS}
                    items={detail?.subscriptions}
                />
            </Flex>
        );
    };

    const renderPMSInfo = () => {
        const pmsLinkedInfo =
            detail &&
            detail.customer &&
            detail.customer.pmsLinkedAccount &&
            detail.customer.pmsLinkedAccount;

        if (pmsLinkedInfo) {
            if (pmsLinkedInfo.isLinked) {
                return (
                    <Flex className="spacingTop" flexDirection="column">
                        <Note
                            variant="positive"
                            style={{ marginBottom: 20, marginTop: 20 }}
                        >
                            Linked to the PMS Account ID:{' '}
                            <b>{pmsLinkedInfo.linkedAccountIdentifier}</b>
                        </Note>
                    </Flex>
                );
            }

            return (
                <Flex
                    flexDirection="row"
                    gap="spacingS"
                    justifyContent="center"
                >
                    <div style={{ width: '50%' }}>
                        <Note
                            variant="negative"
                            style={{ marginBottom: 20, marginTop: 20 }}
                        >
                            Account is not linked.
                        </Note>
                        <Button
                            onClick={() => setShowPMSLinkForm(true)}
                            variant="secondary"
                        >
                            Link PMS Account
                        </Button>
                        {showPMSLinkForm && renderPMSLinkForm()}
                    </div>
                    <div style={{ width: '50%' }}></div>
                </Flex>
            );
        }

        return (
            <Flex flexDirection="row" gap="spacingS" justifyContent="center">
                <div style={{ width: '50%' }}>
                    <Note
                        variant="warning"
                        style={{ marginBottom: 20, marginTop: 20 }}
                    >
                        No information found.
                    </Note>
                </div>
                <div style={{ width: '50%' }}></div>
            </Flex>
        );
    };

    const closePMSModal = () => {
        setShowPMSLinkForm(false);
    };

    const renderPMSLinkForm = () =>
        showPMSLinkForm && (
            <Modal
                onClose={closePMSModal}
                isShown={showPMSLinkForm}
                shouldCloseOnOverlayClick={false}
                size="800px"
            >
                {() => (
                    <>
                        <Modal.Header
                            title="Link PMS Account"
                            onClose={closePMSModal}
                        />
                        <Modal.Content>
                            <Note style={{ marginBottom: 20 }}>
                                Enter the PMS Customer ID. This will send them a
                                verification email to link with their Vetdesk
                                account. Upon clicking the link, the two will be
                                linked and PMS data for that Customer will be
                                viewable in Vetdesk.
                            </Note>
                            {detail?.customer && (
                                <PMSLinkForm
                                    customerGuid={detail?.customer.id}
                                    onClose={closePMSModal}
                                />
                            )}
                        </Modal.Content>
                    </>
                )}
            </Modal>
        );

    return (
        <Modal
            onClose={() => setVisibility(false)}
            isShown={isVisible}
            size="fullWidth"
            shouldCloseOnOverlayClick={false}
        >
            {() => (
                <>
                    <Modal.Header
                        title="Customer Detail"
                        onClose={() => {
                            setVisibility(false);
                            setCurrentTab('first');
                        }}
                    />

                    {renderDetail()}
                    <Modal.Controls>
                        <Button
                            size="small"
                            variant="transparent"
                            onClick={() => {
                                setCurrentTab('first');
                                setVisibility(false);
                            }}
                        >
                            Close
                        </Button>
                    </Modal.Controls>
                </>
            )}
        </Modal>
    );
};
