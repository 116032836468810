/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import {
    FacebookIcon,
    FacebookShareButton,
    TwitterIcon,
    TwitterShareButton,
} from 'react-share';

import {
    Asset,
    Badge,
    Checkbox,
    CopyButton,
    Flex,
    IconButton,
    Menu,
    ModalConfirm,
    Paragraph,
    Skeleton,
    Table,
    TableCellSorting,
    Text,
} from '@contentful/f36-components';
import { formatDateAndTime } from '@contentful/f36-datetime';
import { MoreHorizontalIcon, SettingsIcon } from '@contentful/f36-icons';

import {
    ApiBaseEntity,
    ApiEntityStatus,
    ApiReportedEntryStatus,
} from '../models';
import { PhotoCompetitionEntry } from '../models/photoCompetitions';
import {
    getActiveInactivebadge,
    getBadgeLabelForAppointment,
    getBadgeVariant,
} from '../utilities/badge-helper';
import { flattenList } from '../utilities/flatten-list';

export interface TableListProps<T> {
    columns: {
        label: string;
        type: string;
        field: string;
        width?: number;
        extras?: string;
        isSortable?: boolean;
    }[];
    items: T[] | undefined;
    onEdit?: (item: T) => void;
    onToggleStatus?: (item: T, status: ApiEntityStatus) => void;
    showActiveOnToggleStatusLabel?: boolean;
    onDelete?: (itemId: string) => void;
    onSelectionChange?: (items: T[]) => void;
    onView?: (item: T) => void;
    onSort?: (sort: TableSortPropsExternal) => void;
}

export interface TableSortPropsExternal {
    column: string;
    sortDirection: 'asc' | 'desc'; // api supports this
}

interface TableSortPropsInternal {
    column: string;
    sortDirection: TableCellSorting; // f36 table supports this for sorting
}

interface ColumnComponentProps<T> {
    column: { label: string; type: string; field: string; extras?: string };
    item: T;
}

const ColumnComponent = ({ column, item }: ColumnComponentProps<any>) => {
    const flattenItem = GetFlattenItem(item);

    switch (column.type) {
        case 'badge':
            return (
                <Table.Cell key={`${flattenItem.id}-${column.field}`}>
                    <Badge variant={getBadgeVariant(flattenItem[column.field])}>
                        {(flattenItem[column.field] as unknown) ===
                        ApiEntityStatus.INACTIVE
                            ? 'DRAFT'
                            : 'PUBLISHED'}
                    </Badge>
                </Table.Cell>
            );
        case 'badgeSubscriptionFrequency':
        case 'badgePhotoCompetition':
        case 'badgeNotification':
        case 'badgeActiveStatus':
        case 'badgeSubscription':
        case 'badgeCustomer':
        case 'badgeOrder':
        case 'badgeOrderTracking':
        case 'badgeCoupon':
            return (
                <Table.Cell key={`${flattenItem.id}-${column.field}`}>
                    <Badge
                        variant={getBadgeVariant(
                            flattenItem[column.field],
                            column.type
                        )}
                    >
                        {flattenItem[column.field]}
                    </Badge>
                </Table.Cell>
            );
        case 'badgeAppointment':
            return (
                <Table.Cell key={`${flattenItem.id}-${column.field}`}>
                    <Badge
                        variant={getBadgeVariant(
                            flattenItem[column.field],
                            column.type
                        )}
                    >
                        {getBadgeLabelForAppointment(flattenItem[column.field])}
                    </Badge>
                </Table.Cell>
            );
        case 'badgeActiveInactive':
            return (
                <Table.Cell key={`${flattenItem.id}-${column.field}`}>
                    <Badge
                        variant={getActiveInactivebadge(
                            flattenItem[column.field]
                        )}
                    >
                        {flattenItem[column.field] == true
                            ? 'ACTIVE'
                            : 'INACTIVE'}
                    </Badge>
                </Table.Cell>
            );
        case 'badgeBoolean':
            return (
                <Table.Cell key={`${flattenItem.id}-${column.field}`}>
                    <Badge
                        variant={
                            (flattenItem[column.field] as boolean) === true
                                ? 'positive'
                                : 'negative'
                        }
                    >
                        {(item[column.field] as boolean) === true
                            ? 'Yes'
                            : 'No'}
                    </Badge>
                </Table.Cell>
            );
        case 'list':
            return (
                <Table.Cell key={`${flattenItem.id}-${column.field}`}>
                    <Text>
                        {flattenList(item[column.field], column.extras || '')}
                    </Text>
                </Table.Cell>
            );
        case 'currency':
            return (
                <Table.Cell key={`${flattenItem.id}-${column.field}`}>
                    {/* <Flex
                        justifyContent="space-between"
                        alignItems="center"
                        style={{ paddingTop: 2 }}
                    >
                        <Text>$&nbsp;</Text>
                        <Text>{flattenItem[column.field]}</Text>
                    </Flex> */}
                    {flattenItem[column.field] ? (
                        <Text> ${flattenItem[column.field].toFixed(2)} </Text>
                    ) : (
                        <Text>-</Text>
                    )}
                </Table.Cell>
            );
        case 'date':
            return (
                <Table.Cell key={`${flattenItem.id}-${column.field}`}>
                    <Text>{formatDateAndTime(flattenItem[column.field])}</Text>
                </Table.Cell>
            );
        case 'dateOnly':
            return (
                <Table.Cell key={`${flattenItem.id}-${column.field}`}>
                    <Text>
                        {flattenItem[column.field]
                            ? formatDateAndTime(
                                  flattenItem[column.field],
                                  'day'
                              )
                            : '-'}
                    </Text>
                </Table.Cell>
            );
        case 'image':
            return (
                <Table.Cell key={`${flattenItem.id}-${column.field}`}>
                    <Asset src={flattenItem[column.field]} />
                </Table.Cell>
            );
        case 'images':
            return (
                <Table.Cell key={`${flattenItem.id}-${column.field}`}>
                    <Asset
                        src={GetFirstImageFromImages(flattenItem, column.field)}
                    />
                </Table.Cell>
            );
        case 'share': // for leaderboard social sharing
            return (
                <Table.Cell key={`${flattenItem.id}-${column.field}`}>
                    {GetSocialShareButtons(item)}
                </Table.Cell>
            );
        case 'reportedEntry': // for leaderboard reported
            return (
                <Table.Cell key={`${flattenItem.id}-${column.field}`}>
                    {GetReportedContent(item)}
                </Table.Cell>
            );
        case 'boolean':
            return (
                <Table.Cell key={`${flattenItem.id}-${column.field}`}>
                    <Text>{flattenItem[column.field] ? 'Yes' : 'No'}</Text>
                </Table.Cell>
            );
        case 'string':
        case 'number':
        default:
            return (
                <Table.Cell key={`${flattenItem.id}-${column.field}`}>
                    <Text>{flattenItem[column.field]}</Text>
                </Table.Cell>
            );
    }
};

// handle nested item and array as [column.field] can't handle [0] and nested item in the column's field
const GetFlattenItem = (
    item: { [k: string]: any },
    prefix?: string
): { [k: string]: any } => {
    let flatList: any = {};

    Object.keys(item).forEach((key) => {
        // Check if is an array
        if (Array.isArray(item[key])) {
            // Array, iterate through items and flatten
            item[key].forEach((val: any, index: number) => {
                flatList = {
                    ...flatList,
                    ...GetFlattenItem(
                        val,
                        prefix
                            ? `${prefix}.${key}[${index}]`
                            : `${key}[${index}]`
                    ),
                };
            });
            // Flatten nested object.
        } else if (typeof item[key] === 'object') {
            // Object, flatten
            flatList = {
                ...flatList,
                ...GetFlattenItem(
                    item[key],
                    prefix ? `${prefix}.${key}` : `${key}`
                ),
            };
        } else {
            // plain prop, reassign
            flatList[prefix ? `${prefix}.${key}` : key] = item[key];
        }
    });

    return flatList;
};

const GetFirstImageFromImages = (item: any, field: any) => {
    if (Array.isArray(item[field])) {
        return item[field].length > 0 ? item[field][0].url : '';
    }
    return '';
};

const GetReportedContent = (entry: PhotoCompetitionEntry) => {
    if (!entry.reportedByCustomer) {
        return (
            <>
                {entry.reportStatus == ApiReportedEntryStatus.INAPPROPRIATE ? (
                    <Badge variant="negative">Hidden for viewer</Badge>
                ) : (
                    <Badge variant="positive">Safe for viewer</Badge>
                )}
            </>
        );
    }
    return (
        <>
            <Paragraph>{entry.reportedEntries.length} report/s found</Paragraph>

            {entry.requiresReview ? (
                <Badge variant="warning">Pending review</Badge>
            ) : entry.reportStatus == ApiReportedEntryStatus.INAPPROPRIATE ? (
                <Badge variant="negative">Hidden for viewer</Badge>
            ) : (
                <Badge variant="positive">Safe for viewer</Badge>
            )}
        </>
    );
};

const GetSocialShareButtons = (item: PhotoCompetitionEntry) => {
    const sharingUrl = `${item.socialShareUrl}/${item.id}`;
    if (item.allowSocialSharing == true) {
        return (
            <Flex>
                <FacebookShareButton
                    url={sharingUrl}
                    style={{ cursor: 'default', marginLeft: 5 }}
                >
                    <FacebookIcon size={31} round />
                </FacebookShareButton>{' '}
                <TwitterShareButton
                    url={sharingUrl}
                    style={{ cursor: 'default', marginLeft: 5 }}
                >
                    <TwitterIcon size={31} round />
                </TwitterShareButton>
                <CopyButton
                    size="small"
                    style={{
                        cursor: 'default',
                        borderRadius: '50%',
                        marginLeft: 5,
                    }}
                    value={sharingUrl}
                    tooltipProps={{ placement: 'right' }}
                />
            </Flex>
        );
    } else {
        return 'Sharing not allowed';
    }
};

export const TableList = <T extends ApiBaseEntity>(
    props: TableListProps<T>
) => {
    const {
        columns,
        items,
        onEdit,
        onToggleStatus,
        onDelete,
        onSelectionChange,
        onView,
        onSort,
        showActiveOnToggleStatusLabel,
    } = props;
    const hasActions = onEdit || onToggleStatus || onDelete || onView;
    const hasCheckbox = onSelectionChange;

    const [deleteId, setDeleteId] = useState<string | undefined>(undefined);
    const [selectAll, setSelectAll] = useState<boolean>(false);
    const [checkboxes, setCheckboxes] = useState<string[]>([]);

    const [sorting, setSorting] = useState<TableSortPropsInternal | undefined>(
        undefined
    );

    const toggleCheckbox = (id: string) => {
        if (checkboxes.includes(id)) {
            setCheckboxes(checkboxes.filter((c) => c !== id));
        } else {
            setCheckboxes([...checkboxes, id]);
        }
    };

    useEffect(() => {
        if (selectAll) {
            const ids: string[] = [];
            items?.map((item) => {
                if (item.selectable && item.selectable == true) {
                    ids.push(item.id);
                }
            });
            setCheckboxes(ids || []);
        } else {
            setCheckboxes([]);
        }
    }, [selectAll]);

    useEffect(() => {
        setSelectAll(false);
        setCheckboxes([]);
    }, [items]);

    useEffect(() => {
        onSelectionChange &&
            onSelectionChange(
                items?.filter((item) => checkboxes.includes(item.id)) || []
            );
    }, [checkboxes]);

    const triggerRowClickCallback = (e: any) => {
        if (onView) {
            return onView(e);
        } else if (onEdit) {
            return onEdit(e);
        }
    };

    const handleSort = (column: string) => {
        const sortDirection =
            sorting && sorting.column === column
                ? sorting.sortDirection === TableCellSorting.Ascending
                    ? TableCellSorting.Descending
                    : TableCellSorting.Ascending
                : TableCellSorting.Ascending;

        // for internal Table state
        setSorting({
            column,
            sortDirection,
        } as TableSortPropsInternal);

        // to api call
        onSort &&
            onSort({
                column,
                sortDirection:
                    sortDirection == TableCellSorting.Ascending
                        ? 'asc'
                        : 'desc',
            });
    };

    return (
        <div className="spacingTop">
            <Table>
                <Table.Head>
                    <Table.Row>
                        {hasCheckbox && (
                            <Table.Cell width={23}>
                                <Checkbox
                                    name="select-all-products"
                                    id="select-all-products"
                                    style={{ marginTop: 4 }}
                                    isChecked={selectAll}
                                    onChange={() => setSelectAll(!selectAll)}
                                ></Checkbox>
                            </Table.Cell>
                        )}
                        {columns.map((column) => (
                            <Table.Cell
                                key={column.field}
                                width={column.width}
                                isSortable={column.isSortable ? true : false}
                                onClick={() =>
                                    column.isSortable &&
                                    handleSort(column.field)
                                }
                            >
                                {column.isSortable &&
                                sorting &&
                                sorting.column == column.field ? (
                                    <Text
                                        fontColor="colorPrimary"
                                        fontWeight="fontWeightMedium"
                                        as="u"
                                    >
                                        {column.label}
                                    </Text>
                                ) : (
                                    <Text
                                        fontColor={
                                            column.isSortable
                                                ? 'colorPrimary'
                                                : undefined
                                        }
                                        fontWeight={
                                            column.isSortable
                                                ? 'fontWeightMedium'
                                                : 'fontWeightNormal'
                                        }
                                    >
                                        {column.label}
                                    </Text>
                                )}
                            </Table.Cell>
                        ))}
                        {hasActions && (
                            <Table.Cell align="center" width={32}>
                                <SettingsIcon size="small" variant="muted" />
                            </Table.Cell>
                        )}
                    </Table.Row>
                </Table.Head>
                <Table.Body>
                    {!items ? (
                        <Skeleton.Row
                            rowCount={5}
                            columnCount={columns.length + 1}
                        />
                    ) : items && items.length > 0 ? (
                        items.map((item) => (
                            <Table.Row
                                style={{ cursor: 'pointer' }}
                                key={item.id}
                                onClick={() => triggerRowClickCallback(item)}
                            >
                                {hasCheckbox && (
                                    <Table.Cell
                                        onClick={(e: any) =>
                                            e.stopPropagation()
                                        }
                                    >
                                        {item.selectable && (
                                            <Checkbox
                                                name="select-all-products"
                                                id="select-all-products"
                                                style={{ marginTop: 4 }}
                                                isChecked={checkboxes.includes(
                                                    item.id
                                                )}
                                                onChange={(e) => {
                                                    e.stopPropagation();
                                                    toggleCheckbox(item.id);
                                                }}
                                            ></Checkbox>
                                        )}
                                    </Table.Cell>
                                )}
                                {columns.map((column) =>
                                    ColumnComponent({ column, item })
                                )}
                                {hasActions && (
                                    <Table.Cell>
                                        <Menu>
                                            <Menu.Trigger>
                                                <IconButton
                                                    variant="transparent"
                                                    icon={
                                                        <MoreHorizontalIcon />
                                                    }
                                                    aria-label="toggle menu"
                                                    size="small"
                                                    className="minH16 noPadding"
                                                    onClick={(e: any) =>
                                                        e.stopPropagation()
                                                    }
                                                />
                                            </Menu.Trigger>
                                            <Menu.List>
                                                {onEdit && (
                                                    <Menu.Item
                                                        onClick={(e: any) => {
                                                            e.stopPropagation();
                                                            onEdit(item);
                                                        }}
                                                    >
                                                        Edit
                                                    </Menu.Item>
                                                )}
                                                {onView && (
                                                    <Menu.Item
                                                        onClick={(e: any) => {
                                                            e.stopPropagation();
                                                            onView(item);
                                                        }}
                                                    >
                                                        View
                                                    </Menu.Item>
                                                )}
                                                {onToggleStatus && (
                                                    <Menu.Item
                                                        onClick={(e: any) => {
                                                            e.stopPropagation();
                                                            onToggleStatus(
                                                                item,
                                                                item.status ===
                                                                    ApiEntityStatus.INACTIVE
                                                                    ? ApiEntityStatus.ACTIVE
                                                                    : ApiEntityStatus.INACTIVE
                                                            );
                                                        }}
                                                    >
                                                        {showActiveOnToggleStatusLabel ==
                                                        true
                                                            ? item.status ===
                                                              ApiEntityStatus.ACTIVE
                                                                ? 'Inactivate'
                                                                : 'Activate'
                                                            : item.status ===
                                                              ApiEntityStatus.ACTIVE
                                                            ? 'Unpublish'
                                                            : 'Publish'}
                                                    </Menu.Item>
                                                )}
                                                {onDelete && (
                                                    <Menu.Item
                                                        onClick={(e: any) => {
                                                            e.stopPropagation();
                                                            setDeleteId(
                                                                item.id
                                                            );
                                                        }}
                                                    >
                                                        Delete
                                                    </Menu.Item>
                                                )}
                                            </Menu.List>
                                        </Menu>
                                    </Table.Cell>
                                )}
                            </Table.Row>
                        ))
                    ) : (
                        <Table.Row>
                            <Table.Cell>No Record Found.</Table.Cell>
                        </Table.Row>
                    )}
                </Table.Body>
            </Table>
            <ModalConfirm
                intent="negative"
                isShown={!!deleteId}
                onCancel={() => {
                    setDeleteId(undefined);
                }}
                onConfirm={() => {
                    setDeleteId(undefined);
                    onDelete && deleteId && onDelete(deleteId);
                }}
            >
                <Text>Do you really want to delete this entry?</Text>
            </ModalConfirm>
        </div>
    );
};
