import React from 'react';

import { Spinner } from '@contentful/f36-components';

export const LoadingPage: React.FC = () => {
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                width: '100vw',
            }}
        >
            <Spinner variant="primary" size="large" />
        </div>
    );
};
