import React, { useEffect } from 'react';
import {
    BrowserRouter as Router,
    Navigate,
    Route,
    Routes,
    useNavigate,
} from 'react-router-dom';

import { Button, Card, Heading, Paragraph } from '@contentful/f36-components';

import userManager from './auth/authConfig';
import { useAuth } from './auth/authProvider';
import { LoadingPage } from './components/loading-page';
import Page from './locations/page';
import PageLogin from './locations/page-login';

const SignInCallback: React.FC = () => {
    const navigate = useNavigate();

    useEffect(() => {
        userManager
            .signinRedirectCallback()
            .then(() => {
                navigate('/');
            })
            .catch((error) => {
                console.error('Callback error:', error);
            });
    }, [navigate, userManager]);

    return <LoadingPage />;
};

const SignOutCallback: React.FC = () => {
    const navigate = useNavigate();

    useEffect(() => {
        userManager
            .signoutCallback()
            .then(() => {
                navigate('/');
            })
            .catch((error) => {
                console.error('Callback error:', error);
                navigate('/');
            });
    }, [navigate, userManager]);

    return <LoadingPage />;
};

const SilentRenew = () => {
    useEffect(() => {
        userManager.signinSilentCallback();
    }, []);

    return <LoadingPage />;
};

const ProtectedRoute = ({ children }: any) => {
    const { isAuthenticated, profile, signoutRedirect } = useAuth();

    if (isAuthenticated) {
        const role = profile && profile['vetdesk-role'];

        if (role == 'Customer') {
            return (
                <Card>
                    <Heading>Access denied</Heading>
                    <Paragraph>
                        Sorry, You do not have permission to access this page.
                    </Paragraph>
                    <Button size="small" onClick={signoutRedirect}>
                        Sign Out
                    </Button>
                </Card>
            );
        } else {
            return children;
        }
    } else {
        return <Navigate to="/login" />;
    }
};

const App = () => {
    return (
        <Router>
            <div>
                <Routes>
                    <Route path="/login" element={<PageLogin />} />
                    <Route
                        path="/signin_redirect"
                        element={<SignInCallback />}
                    />
                    <Route
                        path="/signout_redirect"
                        element={<SignOutCallback />}
                    />
                    <Route path="/silent-renew" element={<SilentRenew />} />
                    <Route
                        path="/*"
                        element={
                            <ProtectedRoute>
                                <Page />
                            </ProtectedRoute>
                        }
                    />
                </Routes>
            </div>
        </Router>
    );
};

export default App;
