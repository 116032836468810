import { css } from 'emotion';
import React, { useEffect, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import Select from 'react-select';

import {
    Button,
    Card,
    Flex,
    FormControl,
    Heading,
    Modal,
    Paragraph,
    Stack,
    Tabs,
    Text,
    TextInput,
} from '@contentful/f36-components';
import { DeleteIcon } from '@contentful/f36-icons';

import { ENV } from '../../../environments';
import { useVetdeskMiddleware } from '../../../hooks';
import { Calendar, DayOptions } from '../../../models';
import { formatDateToISOString } from '../../../utilities/date-helper';
import { OPENING_HOUR_COLUMNS } from '../../../utilities/table-columns';
import { DatetimePicker } from '../../datetime-picker';
import { TableList } from '../../table-list';
import { TimePicker } from '../../time-picker';

const API_URL = `${ENV.middlewareBaseUrl}/api/v2/admin/calendar`;

export const OpeningHours: React.FC = () => {
    const { runRequest } = useVetdeskMiddleware();

    // Component states
    const [calendarList, setCalendarList] = useState<Calendar[]>();
    const [editItem, setEditItem] = useState<Calendar>();
    const [currentTab, setCurrentTab] = useState('openPeriods');

    // Form setup
    const defaultValues = {
        openPeriods: editItem?.openPeriods || [
            {
                days: [],
                startTime: '',
                endTime: '',
            },
        ],
        closedPeriods: editItem?.closedPeriods || [],
    };
    const form = useForm<Calendar>({ defaultValues, shouldUnregister: false });
    const {
        control,
        handleSubmit,
        formState: { errors },
        reset,
        watch,
    } = form;
    const openPeriodsFieldArray = useFieldArray({
        name: 'openPeriods',
        control,
        rules: { required: 'At least one open period is required' },
        shouldUnregister: false,
    });
    const {
        fields: openPeriodsFields,
        append: appendOpenPeriods,
        remove: removeOpenPeriods,
    } = openPeriodsFieldArray;
    const closedPeriodsFieldArray = useFieldArray({
        name: 'closedPeriods',
        control,
    });
    const {
        fields: closedPeriodsFields,
        append: appendClosedPeriods,
        remove: removeClosedPeriods,
    } = closedPeriodsFieldArray;

    useEffect(() => {
        fetchCalendarList();
    }, []);

    useEffect(() => {
        reset(defaultValues);
    }, [editItem]);

    const fetchCalendarList = () => {
        setCalendarList(undefined); // to display skeleton when fetching

        runRequest('GET', `${API_URL}`).then((res) => {
            const response = res as Calendar[];
            setCalendarList(response);
        });
    };

    const onModalClose = () => {
        setEditItem(undefined);
    };

    const onSubmit = async (data: any) => {
        const updatedData = { ...editItem, ...data };

        if (updatedData.id) {
            await runRequest(
                'PUT',
                `${API_URL}/${updatedData.id}`,
                updatedData
            ).then(
                (res) => {
                    fetchCalendarList();
                    onModalClose();
                },
                (error) => console.warn('Oops', error)
            );
        }
    };

    const renderEditModel = () => {
        return (
            <Modal
                size="800px"
                onClose={onModalClose}
                isShown={editItem != null}
                shouldCloseOnOverlayClick={false}
            >
                {() => (
                    <>
                        <Modal.Header title="Edit" onClose={onModalClose} />
                        <Modal.Content>
                            <Tabs
                                currentTab={currentTab}
                                onTabChange={(e: string) => setCurrentTab(e)}
                            >
                                <Tabs.List style={{ marginBottom: 20 }}>
                                    <Tabs.Tab panelId="openPeriods">
                                        Open Periods
                                    </Tabs.Tab>
                                    <Tabs.Tab panelId="closedPeriods">
                                        Closed Periods
                                    </Tabs.Tab>
                                </Tabs.List>
                                <Tabs.Panel id="openPeriods">
                                    <Card>
                                        {openPeriodsFields.map(
                                            (openPeriod, openPeriodIndex) => (
                                                <Flex
                                                    key={openPeriod.id}
                                                    fullWidth
                                                    flexDirection="column"
                                                    className={css({
                                                        borderBottom:
                                                            '1px solid #bfbcbc',
                                                    })}
                                                    marginBottom="spacingL"
                                                >
                                                    {openPeriodIndex > 0 && (
                                                        <Flex
                                                            fullWidth
                                                            justifyContent="end"
                                                        >
                                                            <Button
                                                                variant="transparent"
                                                                size="small"
                                                                aria-label="remove open period"
                                                                onClick={() =>
                                                                    removeOpenPeriods(
                                                                        openPeriodIndex
                                                                    )
                                                                }
                                                                startIcon={
                                                                    <DeleteIcon />
                                                                }
                                                            >
                                                                Remove
                                                            </Button>
                                                        </Flex>
                                                    )}

                                                    <FormControl
                                                        style={{
                                                            flexGrow: 1,
                                                        }}
                                                    >
                                                        <FormControl.Label>
                                                            Days
                                                        </FormControl.Label>
                                                        <Controller
                                                            control={control}
                                                            name={`openPeriods.${openPeriodIndex}.days`}
                                                            render={({
                                                                field: {
                                                                    onChange,
                                                                    value,
                                                                },
                                                            }) => (
                                                                <Select
                                                                    value={DayOptions?.filter(
                                                                        (
                                                                            option
                                                                        ) =>
                                                                            value?.includes(
                                                                                option.value
                                                                            )
                                                                    )}
                                                                    onChange={(
                                                                        val
                                                                    ) => {
                                                                        const values =
                                                                            val.map(
                                                                                (
                                                                                    c
                                                                                ) =>
                                                                                    c.value
                                                                            );
                                                                        onChange(
                                                                            values
                                                                        );
                                                                    }}
                                                                    options={
                                                                        DayOptions
                                                                    }
                                                                    styles={{
                                                                        menuPortal:
                                                                            (
                                                                                base
                                                                            ) => ({
                                                                                ...base,
                                                                                zIndex: 9999,
                                                                            }),
                                                                    }}
                                                                    menuPortalTarget={
                                                                        document.body
                                                                    }
                                                                    isMulti
                                                                />
                                                            )}
                                                            rules={{
                                                                required:
                                                                    'Days is required',
                                                            }}
                                                        />
                                                        {errors?.openPeriods?.[
                                                            openPeriodIndex
                                                        ]?.days && (
                                                            <Text fontColor="red500">
                                                                {errors?.openPeriods?.[
                                                                    openPeriodIndex
                                                                ]?.days?.message?.toString()}
                                                            </Text>
                                                        )}
                                                    </FormControl>

                                                    <Flex
                                                        fullWidth
                                                        gap="spacingM"
                                                    >
                                                        <FormControl
                                                            style={{
                                                                width: '50%',
                                                            }}
                                                        >
                                                            <FormControl.Label>
                                                                Start Time
                                                            </FormControl.Label>
                                                            <Controller
                                                                control={
                                                                    control
                                                                }
                                                                name={`openPeriods.${openPeriodIndex}.startTime`}
                                                                render={({
                                                                    field: {
                                                                        onChange,
                                                                        value,
                                                                    },
                                                                }) => (
                                                                    <TimePicker
                                                                        value={
                                                                            value
                                                                        }
                                                                        handleChange={
                                                                            onChange
                                                                        }
                                                                    />
                                                                )}
                                                                rules={{
                                                                    required:
                                                                        'Start time is required',
                                                                }}
                                                            />
                                                            {errors
                                                                ?.openPeriods?.[
                                                                openPeriodIndex
                                                            ]?.startTime && (
                                                                <Text fontColor="red500">
                                                                    {errors?.openPeriods?.[
                                                                        openPeriodIndex
                                                                    ]?.startTime?.message?.toString()}
                                                                </Text>
                                                            )}
                                                        </FormControl>
                                                        <FormControl
                                                            style={{
                                                                width: '50%',
                                                            }}
                                                        >
                                                            <FormControl.Label>
                                                                End Time
                                                            </FormControl.Label>
                                                            <Controller
                                                                control={
                                                                    control
                                                                }
                                                                name={`openPeriods.${openPeriodIndex}.endTime`}
                                                                render={({
                                                                    field: {
                                                                        onChange,
                                                                        value,
                                                                    },
                                                                }) => (
                                                                    <TimePicker
                                                                        value={
                                                                            value
                                                                        }
                                                                        handleChange={
                                                                            onChange
                                                                        }
                                                                    />
                                                                )}
                                                                rules={{
                                                                    required:
                                                                        'End time is required',
                                                                    validate: (
                                                                        value
                                                                    ) => {
                                                                        const startTime =
                                                                            watch(
                                                                                `openPeriods.${openPeriodIndex}.startTime`
                                                                            );
                                                                        if (
                                                                            startTime &&
                                                                            value &&
                                                                            value <=
                                                                                startTime
                                                                        ) {
                                                                            return 'End time must be later than start time';
                                                                        }
                                                                        return true;
                                                                    },
                                                                }}
                                                            />
                                                            {errors
                                                                ?.openPeriods?.[
                                                                openPeriodIndex
                                                            ]?.endTime && (
                                                                <Text fontColor="red500">
                                                                    {errors?.openPeriods?.[
                                                                        openPeriodIndex
                                                                    ]?.endTime?.message?.toString()}
                                                                </Text>
                                                            )}
                                                        </FormControl>
                                                    </Flex>
                                                </Flex>
                                            )
                                        )}
                                        <Button
                                            size="small"
                                            variant="secondary"
                                            onClick={() =>
                                                appendOpenPeriods({
                                                    days: [],
                                                    startTime: '',
                                                    endTime: '',
                                                })
                                            }
                                        >
                                            Add Open Period
                                        </Button>
                                        {errors?.openPeriods?.root && (
                                            <Text
                                                fontColor="red500"
                                                marginLeft="spacingS"
                                            >
                                                {errors?.openPeriods?.root?.message?.toString()}
                                            </Text>
                                        )}
                                    </Card>
                                </Tabs.Panel>
                                <Tabs.Panel id="closedPeriods">
                                    <Card>
                                        {closedPeriodsFields.map(
                                            (
                                                closedPeriod,
                                                closedPeriodIndex
                                            ) => (
                                                <Flex
                                                    key={closedPeriod.id}
                                                    fullWidth
                                                    flexDirection="column"
                                                    className={css({
                                                        borderBottom:
                                                            '1px solid #bfbcbc',
                                                    })}
                                                    marginBottom="spacingL"
                                                >
                                                    <Flex
                                                        fullWidth
                                                        justifyContent="end"
                                                    >
                                                        <Button
                                                            variant="transparent"
                                                            size="small"
                                                            aria-label="remove closed period"
                                                            onClick={() =>
                                                                removeClosedPeriods(
                                                                    closedPeriodIndex
                                                                )
                                                            }
                                                            startIcon={
                                                                <DeleteIcon />
                                                            }
                                                        >
                                                            Remove
                                                        </Button>
                                                    </Flex>
                                                    <FormControl
                                                        style={{
                                                            flexGrow: 1,
                                                        }}
                                                    >
                                                        <FormControl.Label>
                                                            Name
                                                        </FormControl.Label>
                                                        <Controller
                                                            control={control}
                                                            name={`closedPeriods.${closedPeriodIndex}.name`}
                                                            render={({
                                                                field: {
                                                                    onChange,
                                                                    value,
                                                                },
                                                            }) => (
                                                                <TextInput
                                                                    value={
                                                                        value
                                                                    }
                                                                    onChange={
                                                                        onChange
                                                                    }
                                                                />
                                                            )}
                                                            rules={{
                                                                required:
                                                                    'Name is required',
                                                            }}
                                                        />
                                                        {errors
                                                            ?.closedPeriods?.[
                                                            closedPeriodIndex
                                                        ]?.name && (
                                                            <Text fontColor="red500">
                                                                {errors?.closedPeriods?.[
                                                                    closedPeriodIndex
                                                                ]?.name?.message?.toString()}
                                                            </Text>
                                                        )}
                                                    </FormControl>
                                                    <FormControl>
                                                        <FormControl.Label>
                                                            Start
                                                        </FormControl.Label>
                                                        <Controller
                                                            control={control}
                                                            name={`closedPeriods.${closedPeriodIndex}.start`}
                                                            render={({
                                                                field: {
                                                                    onChange,
                                                                    value,
                                                                },
                                                            }) => (
                                                                <DatetimePicker
                                                                    fromDate={
                                                                        new Date()
                                                                    }
                                                                    value={
                                                                        value
                                                                            ? new Date(
                                                                                  value
                                                                              )
                                                                            : undefined
                                                                    }
                                                                    onSelect={(
                                                                        date: Date
                                                                    ) => {
                                                                        if (
                                                                            date
                                                                        ) {
                                                                            onChange(
                                                                                formatDateToISOString(
                                                                                    date
                                                                                )
                                                                            );
                                                                        } else {
                                                                            onChange(
                                                                                ''
                                                                            );
                                                                        }
                                                                    }}
                                                                />
                                                            )}
                                                            rules={{
                                                                required:
                                                                    'Start is required',
                                                            }}
                                                        />
                                                        {errors
                                                            ?.closedPeriods?.[
                                                            closedPeriodIndex
                                                        ]?.start && (
                                                            <Text fontColor="red500">
                                                                {errors?.closedPeriods?.[
                                                                    closedPeriodIndex
                                                                ]?.start?.message?.toString()}
                                                            </Text>
                                                        )}
                                                    </FormControl>
                                                    <FormControl>
                                                        <FormControl.Label>
                                                            End
                                                        </FormControl.Label>
                                                        <Controller
                                                            control={control}
                                                            name={`closedPeriods.${closedPeriodIndex}.end`}
                                                            render={({
                                                                field: {
                                                                    onChange,
                                                                    value,
                                                                },
                                                            }) => (
                                                                <DatetimePicker
                                                                    fromDate={
                                                                        new Date()
                                                                    }
                                                                    value={
                                                                        value
                                                                            ? new Date(
                                                                                  value
                                                                              )
                                                                            : undefined
                                                                    }
                                                                    onSelect={(
                                                                        date: Date
                                                                    ) => {
                                                                        if (
                                                                            date
                                                                        ) {
                                                                            onChange(
                                                                                formatDateToISOString(
                                                                                    date
                                                                                )
                                                                            );
                                                                        } else {
                                                                            onChange(
                                                                                ''
                                                                            );
                                                                        }
                                                                    }}
                                                                />
                                                            )}
                                                            rules={{
                                                                required:
                                                                    'End is required',
                                                                validate: (
                                                                    endValue
                                                                ) => {
                                                                    const startValue =
                                                                        watch(
                                                                            `closedPeriods.${closedPeriodIndex}.start`
                                                                        );
                                                                    if (
                                                                        !startValue
                                                                    ) {
                                                                        return true; // Skip validation if start is not set
                                                                    }
                                                                    return (
                                                                        new Date(
                                                                            endValue
                                                                        ) >
                                                                            new Date(
                                                                                startValue
                                                                            ) ||
                                                                        'End must be after start'
                                                                    );
                                                                },
                                                            }}
                                                        />
                                                        {errors
                                                            ?.closedPeriods?.[
                                                            closedPeriodIndex
                                                        ]?.end && (
                                                            <Text fontColor="red500">
                                                                {errors?.closedPeriods?.[
                                                                    closedPeriodIndex
                                                                ]?.end?.message?.toString()}
                                                            </Text>
                                                        )}
                                                    </FormControl>
                                                </Flex>
                                            )
                                        )}
                                        <Button
                                            size="small"
                                            variant="secondary"
                                            onClick={() =>
                                                appendClosedPeriods({
                                                    name: '',
                                                    start: '',
                                                    end: '',
                                                })
                                            }
                                        >
                                            Add Closed Period
                                        </Button>
                                        {errors?.closedPeriods?.root && (
                                            <Text
                                                fontColor="red500"
                                                marginLeft="spacingS"
                                            >
                                                {errors?.closedPeriods?.root?.message?.toString()}
                                            </Text>
                                        )}
                                    </Card>
                                </Tabs.Panel>
                            </Tabs>
                        </Modal.Content>
                        <Modal.Controls>
                            <Stack>
                                <Button
                                    size="small"
                                    variant="transparent"
                                    onClick={onModalClose}
                                >
                                    Close
                                </Button>
                                <Button
                                    size="small"
                                    variant="positive"
                                    onClick={() => handleSubmit(onSubmit)()}
                                >
                                    Save
                                </Button>
                            </Stack>
                        </Modal.Controls>
                    </>
                )}
            </Modal>
        );
    };

    return (
        <>
            <Stack justifyContent="space-between">
                <div>
                    <Heading>Opening hours</Heading>
                    <Paragraph>
                        Set your open periods and easily exclude specific dates
                        or times for special circumstances such as public
                        holidays, sick days, or other closures.
                    </Paragraph>
                </div>
            </Stack>

            <TableList<Calendar>
                columns={OPENING_HOUR_COLUMNS}
                items={calendarList}
                onEdit={(item) => setEditItem(item)}
            />

            {renderEditModel()}
        </>
    );
};
