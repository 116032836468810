import { css } from 'emotion';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import ReactSelect from 'react-select';
import {
    BtnBold,
    BtnBulletList,
    BtnItalic,
    BtnLink,
    BtnNumberedList,
    BtnUnderline,
    Editor,
    EditorProvider,
    Toolbar,
} from 'react-simple-wysiwyg';

import {
    Badge,
    Button,
    Card,
    DragHandle,
    Flex,
    FormControl,
    Heading,
    Modal,
    Paragraph,
    Select,
    Skeleton,
    Stack,
    Switch,
    Text,
    TextInput,
} from '@contentful/f36-components';
import { EditIcon, ToggleIcon } from '@contentful/f36-icons';
import { DndContext } from '@dnd-kit/core';
import {
    arrayMove,
    SortableContext,
    useSortable,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { htmlToSlate } from '@slate-serializers/html';

import { ENV } from '../../../environments';
import { useVetdeskMiddleware } from '../../../hooks';
import { AppointmentType, Species } from '../../../models';

const API_URL = `${ENV.middlewareBaseUrl}/api/v2/admin/appointment/appointment-type`;
const API_Species_URL = `${ENV.middlewareBaseUrl}/api/v2/admin/species`;
const API_URL_SYNC = `${ENV.middlewareBaseUrl}/api/v2/admin/pms/sync/appointment-type`;

export const AppointmentTypes: React.FC = () => {
    const styles = {
        card: css({
            // This lets us change z-index when dragging
            position: 'relative',
        }),
        dragHandle: css({
            alignSelf: 'stretch',
        }),
    };
    const [draggableItems, setDraggableItems] = useState<
        AppointmentType[] | undefined
    >(undefined);
    const [editItem, setEditItem] = useState(null);
    const [speciesList, setSpeciesList] = useState<Species[]>([]);

    const { runRequest } = useVetdeskMiddleware();
    const fetchAppointmentTypes = () => {
        setDraggableItems(undefined); // to display skeleton when fetching

        runRequest('GET', `${API_URL}`).then((res) => {
            const response = res as AppointmentType[];
            setDraggableItems(response);
        });
    };

    const fetchSpecies = () => {
        runRequest('GET', `${API_Species_URL}`).then((res) => {
            const response = res as Species[];
            setSpeciesList(response);
        });
    };

    const {
        handleSubmit,
        formState: { errors },
        reset,
        watch,
        setValue,
        getValues,
        trigger,
        control,
    } = useForm();

    useEffect(() => {
        fetchAppointmentTypes();
        fetchSpecies();
    }, []);

    function SortableCard({ id, value }: any) {
        const {
            attributes,
            listeners,
            setNodeRef,
            transform,
            transition,
            active,
        } = useSortable({
            id,
        });
        const zIndex = active && active.id === id ? 1 : 0;
        const style = {
            transform: CSS.Translate.toString(transform),
            transition,
            zIndex,
        };

        return (
            <Card
                className={styles.card}
                dragHandleRender={() => (
                    <DragHandle
                        as="button"
                        className={styles.dragHandle}
                        label="Move card"
                        {...attributes}
                        {...listeners}
                    />
                )}
                padding="none"
                withDragHandle
                ref={setNodeRef}
                style={style}
            >
                <Flex
                    justifyContent="space-between"
                    alignItems="center"
                    padding="spacingS"
                >
                    <span>
                        <b>{value.name}</b>
                    </span>{' '}
                    <Flex
                        justifyContent="space-between"
                        alignItems="center"
                        gap="spacingXl"
                    >
                        {value.isActive ? (
                            <Badge variant="positive">ACTIVE</Badge>
                        ) : (
                            <Badge variant="negative">INACTIVE</Badge>
                        )}
                        <Button
                            onClick={() => {
                                reset(value); // update the useForm with the selected item
                                setEditItem(value);
                            }}
                            startIcon={<EditIcon />}
                            size="small"
                        >
                            Edit
                        </Button>
                    </Flex>
                </Flex>
            </Card>
        );
    }

    const updateSort = async (data: AppointmentType[]) => {
        await runRequest('PUT', API_URL, data).then(
            (res) => {
                fetchAppointmentTypes();
            },
            (error) => console.warn('Oops', error)
        );
    };

    const handleDragEnd = (event: { active: any; over: any }) => {
        const { active, over } = event;

        if (active && over && active.id !== over.id) {
            setDraggableItems((items) => {
                if (items) {
                    const oldIndex = items.findIndex(
                        (item) => item.id === active.id
                    );
                    const newIndex = items.findIndex(
                        (item) => item.id === over.id
                    );
                    const updatedItems = arrayMove(items, oldIndex, newIndex);
                    updateSort(updatedItems);
                    return updatedItems;
                }
            });
        }
    };

    const onSubmit = async (data: any) => {
        console.log('submitted data', data);

        if (data.importantInformation) {
            data.importantInformationSlate = JSON.stringify(
                htmlToSlate(data.importantInformation)
            );
        }

        await runRequest('PUT', `${API_URL}/${data.id}`, data).then(
            (res) => {
                fetchAppointmentTypes();
                setEditItem(null);
            },
            (error) => console.warn('Oops', error)
        );
    };

    const syncFromPMS = () => {
        setDraggableItems(undefined); // to display skeleton when fetching

        runRequest('POST', `${API_URL_SYNC}`).then((res) => {
            const response = res as AppointmentType[];
            setDraggableItems(response);
        });
    };

    const isEnabledImportantInfo = watch('enabledImportantInformation');

    const renderEditModel = () => {
        return (
            <Modal
                size="800px"
                onClose={() => setEditItem(null)}
                isShown={editItem != null}
                shouldCloseOnOverlayClick={false}
            >
                {() => (
                    <>
                        <Modal.Header
                            title="Edit"
                            onClose={() => setEditItem(null)}
                        />
                        <Modal.Content>
                            <FormControl>
                                <FormControl.Label>Status</FormControl.Label>
                                <Controller
                                    name="isActive"
                                    control={control}
                                    render={({ field }) => (
                                        <Switch
                                            isChecked={field.value}
                                            onChange={field.onChange}
                                        >
                                            {field.value == true ? 'On' : 'Off'}
                                        </Switch>
                                    )}
                                />
                                <Paragraph>
                                    Toggle on/off to deactivate or activate
                                    without losing any information.
                                </Paragraph>
                            </FormControl>
                            <FormControl>
                                <FormControl.Label>
                                    Appointment type name{' '}
                                    <Text fontColor="red500">*</Text>
                                </FormControl.Label>
                                <Controller
                                    name="name"
                                    control={control}
                                    rules={{
                                        required: 'This field is required',
                                    }}
                                    render={({ field }) => (
                                        <TextInput
                                            {...field}
                                            id="name"
                                            placeholder="Enter text"
                                        />
                                    )}
                                />
                                <Paragraph>
                                    PMS name: {getValues('pmsName')}
                                </Paragraph>
                            </FormControl>

                            <FormControl>
                                <FormControl.Label>
                                    Duration in minutes{' '}
                                    <Text fontColor="red500">*</Text>
                                </FormControl.Label>
                                <Controller
                                    name="duration"
                                    control={control}
                                    rules={{
                                        required: 'This field is required',
                                    }}
                                    render={({ field }) => (
                                        <TextInput
                                            {...field}
                                            id="duration"
                                            type="number"
                                            placeholder="Enter text"
                                        />
                                    )}
                                />
                            </FormControl>

                            <FormControl>
                                <FormControl.Label>
                                    Select Species{' '}
                                    <Text fontColor="red500">*</Text>
                                </FormControl.Label>
                                <Controller
                                    control={control}
                                    name="species"
                                    render={({
                                        field: { onChange, value },
                                    }) => {
                                        const options = speciesList?.map(
                                            (x) => ({
                                                value: x.id,
                                                label: x.name,
                                            })
                                        );
                                        return (
                                            <div
                                                style={{
                                                    width: '100%',
                                                }}
                                            >
                                                <ReactSelect
                                                    value={options?.filter(
                                                        (c) =>
                                                            value?.includes(
                                                                c.value
                                                            )
                                                    )}
                                                    onChange={(val) => {
                                                        const values = val.map(
                                                            (c) => c.value
                                                        );
                                                        onChange(values);
                                                    }}
                                                    options={options}
                                                    styles={{
                                                        menuPortal: (base) => ({
                                                            ...base,
                                                            zIndex: 9999,
                                                        }),
                                                    }}
                                                    menuPortalTarget={
                                                        document.body
                                                    }
                                                    isMulti
                                                />
                                            </div>
                                        );
                                    }}
                                />
                                {errors.appointmentTypes && (
                                    <Text fontColor="red500">
                                        {errors.appointmentTypes.message?.toString()}
                                    </Text>
                                )}
                                <Paragraph>
                                    Select the species to include for this
                                    appointment type.
                                </Paragraph>
                            </FormControl>

                            <FormControl>
                                <FormControl.Label>
                                    Client type{' '}
                                    <Text fontColor="red500">*</Text>
                                </FormControl.Label>
                                <Controller
                                    control={control}
                                    name="customerStatusTypeId"
                                    render={({ field }) => {
                                        return (
                                            <div
                                                style={{
                                                    width: '100%',
                                                }}
                                            >
                                                <Select
                                                    {...field}
                                                    style={{ minWidth: 100 }}
                                                >
                                                    <Select.Option value="1">
                                                        New Customers Only
                                                    </Select.Option>
                                                    <Select.Option value="2">
                                                        Existing Customers Only
                                                    </Select.Option>
                                                    <Select.Option value="3">
                                                        All Customers
                                                    </Select.Option>
                                                </Select>
                                            </div>
                                        );
                                    }}
                                />
                                {errors.customerStatusTypeId && (
                                    <Text fontColor="red500">
                                        {errors.customerStatusTypeId.message?.toString()}
                                    </Text>
                                )}
                            </FormControl>

                            <FormControl>
                                <FormControl.Label>
                                    Enable important note
                                </FormControl.Label>
                                <Controller
                                    name="enabledImportantInformation"
                                    control={control}
                                    render={({ field }) => (
                                        <Switch
                                            isChecked={field.value}
                                            onChange={field.onChange}
                                        >
                                            {field.value == true ? 'On' : 'Off'}
                                        </Switch>
                                    )}
                                />

                                <Paragraph>
                                    Turn this on to add an extra step for
                                    including important notes or terms and
                                    conditions for this appointment type.
                                </Paragraph>
                            </FormControl>
                            {isEnabledImportantInfo && (
                                <>
                                    <FormControl>
                                        <FormControl.Label>
                                            Important note{' '}
                                            <Text fontColor="red500">*</Text>
                                        </FormControl.Label>
                                        <Controller
                                            name="importantInformation"
                                            control={control}
                                            rules={{
                                                required:
                                                    'This field is required',
                                            }}
                                            render={({ field }) => (
                                                <EditorProvider>
                                                    <Editor
                                                        value={field.value}
                                                        placeholder="Enter notes, terms and conditions, or special instruction here."
                                                        onChange={(e) => {
                                                            setValue(
                                                                'importantInformation',
                                                                e.target.value
                                                            );
                                                            trigger(
                                                                'importantInformation'
                                                            );
                                                        }}
                                                    >
                                                        <Toolbar>
                                                            <BtnBold />
                                                            <BtnItalic />
                                                            <BtnUnderline />
                                                            <BtnNumberedList />
                                                            <BtnBulletList />
                                                            <BtnLink />
                                                        </Toolbar>
                                                    </Editor>
                                                </EditorProvider>
                                            )}
                                        />
                                        {errors.importantInformation && (
                                            <Text fontColor="red500">
                                                {errors.importantInformation.message?.toString()}
                                            </Text>
                                        )}
                                    </FormControl>
                                    <FormControl>
                                        <FormControl.Label>
                                            Enable checkbox for accepting Terms
                                            and Conditions
                                        </FormControl.Label>
                                        <Controller
                                            name="hasAcceptCheckbox"
                                            control={control}
                                            render={({ field }) => (
                                                <Switch
                                                    isChecked={field.value}
                                                    onChange={field.onChange}
                                                >
                                                    {field.value == true
                                                        ? 'On'
                                                        : 'Off'}
                                                </Switch>
                                            )}
                                        />
                                        <Paragraph>
                                            Customers must check this box to
                                            proceed to the next step.
                                        </Paragraph>
                                    </FormControl>
                                </>
                            )}
                        </Modal.Content>
                        <Modal.Controls>
                            <Button
                                size="small"
                                variant="transparent"
                                onClick={() => {
                                    setEditItem(null);
                                    // remove();
                                    // reset();
                                }}
                            >
                                Close
                            </Button>
                            <Button
                                size="small"
                                variant="positive"
                                onClick={() => handleSubmit(onSubmit)()}
                            >
                                Save
                            </Button>
                        </Modal.Controls>
                    </>
                )}
            </Modal>
        );
    };

    return (
        <>
            <Stack justifyContent="space-between">
                <div>
                    <Heading>Appointment types</Heading>

                    <Paragraph style={{ marginTop: 10 }}>
                        Update appointment types by editing names or adding
                        important notes, and use drag-and-drop to arrange their
                        order in the booking form.{' '}
                    </Paragraph>
                </div>

                <Button
                    startIcon={<ToggleIcon />}
                    variant="secondary"
                    size="small"
                    onClick={syncFromPMS}
                >
                    Refresh from PMS
                </Button>
            </Stack>

            {draggableItems ? (
                <DndContext onDragEnd={handleDragEnd}>
                    <SortableContext
                        items={draggableItems.map((x) => x.id)}
                        strategy={verticalListSortingStrategy}
                    >
                        <Flex flexDirection="column" gap="spacingS">
                            {draggableItems.map((item) => (
                                <SortableCard
                                    key={item.id}
                                    id={item.id}
                                    value={item}
                                />
                            ))}
                        </Flex>
                    </SortableContext>
                </DndContext>
            ) : (
                <Skeleton.Container>
                    <Skeleton.BodyText numberOfLines={4} />
                </Skeleton.Container>
            )}
            {renderEditModel()}
        </>
    );
};
