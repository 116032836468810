import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import {
    Button,
    Form,
    FormControl,
    Text,
    TextInput,
} from '@contentful/f36-components';

import { ENV } from '../../environments';
import { useVetdeskMiddleware } from '../../hooks';

// Define the types for the form data
interface PMSLinkFormFields {
    pmsCustomerID: string;
    verifyPmsCustomerID: string;
}

interface PMSLinkFormProps {
    onClose: () => void;
    customerGuid: string;
}

const PMSLinkForm: React.FC<PMSLinkFormProps> = (props: PMSLinkFormProps) => {
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm<PMSLinkFormFields>();

    const { runRequest } = useVetdeskMiddleware();

    // Watch the value of the PMS Customer ID input
    const pmsCustomerID = watch('pmsCustomerID');

    // Handle form submission
    const onSubmit: SubmitHandler<PMSLinkFormFields> = async (data) => {
        const url = `${ENV.middlewareBaseUrl}/api/v2/admin/linked-account/confirm-link`;

        const dataToSubmit = {
            id: props.customerGuid,
            pmsIdentifier: data.pmsCustomerID,
        };

        await runRequest('POST', url, dataToSubmit).then(
            (res) => {
                props.onClose();
            },
            (error) => console.warn('Oops', error)
        );
    };

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <FormControl>
                <FormControl.Label>
                    Enter PMS Customer ID <Text fontColor="red500">*</Text>
                </FormControl.Label>
                <TextInput
                    {...register('pmsCustomerID', {
                        required: 'PMS Customer ID is required.',
                    })}
                />
                {errors && errors.pmsCustomerID && (
                    <Text fontColor="red500">
                        {errors.pmsCustomerID.message}
                    </Text>
                )}
            </FormControl>
            <FormControl>
                <FormControl.Label>
                    Verify PMS Customer ID <Text fontColor="red500">*</Text>
                </FormControl.Label>
                <TextInput
                    {...register('verifyPmsCustomerID', {
                        required:
                            'Verification of PMS Customer ID is required.',
                        validate: (value) =>
                            value === pmsCustomerID ||
                            'PMS Customer IDs do not match.',
                    })}
                />
                {errors && errors.verifyPmsCustomerID && (
                    <Text fontColor="red500">
                        {errors.verifyPmsCustomerID.message}
                    </Text>
                )}
            </FormControl>

            <FormControl>
                <Button type="submit" size="small" variant="primary">
                    Submit
                </Button>
                <Button
                    style={{ marginLeft: 10 }}
                    type="submit"
                    size="small"
                    onClick={props.onClose}
                >
                    Cancel
                </Button>
            </FormControl>
        </Form>
    );
};

export default PMSLinkForm;
