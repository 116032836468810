import React, { useState } from 'react';

import { Flex, Heading, Paragraph, Tabs } from '@contentful/f36-components';

import { AppointmentTypes } from '../components/settings/booking/appointment-types';
import { CalendarList } from '../components/settings/booking/calender-list';
import { EmergencyInformation } from '../components/settings/booking/emergency-information';
import { OpeningHours } from '../components/settings/booking/opening-hours';
import { SpeciesTypes } from '../components/settings/booking/species-types';
import { WelcomeMessage } from '../components/settings/booking/welcome-message';

export const PageBookingForm = () => {
    const [currentTab, setCurrentTab] = useState('AppointmentTypes');
    return (
        <>
            <Flex flexDirection="column" alignItems="left">
                <Heading as="h1">Booking Form</Heading>
                <Paragraph>
                    Booking form settings to customise appointment type, welcome
                    message, and emergency message for the booking form that
                    will be used by customers via the app and website.
                </Paragraph>
            </Flex>
            <Flex className="spacingTop" flexDirection="column">
                <Tabs
                    currentTab={currentTab}
                    onTabChange={(e: string) => setCurrentTab(e)}
                >
                    <Tabs.List style={{ marginBottom: 20 }}>
                        <Tabs.Tab panelId="AppointmentTypes">
                            Appointment types
                        </Tabs.Tab>
                        <Tabs.Tab panelId="welcomeMessage">
                            Welcome message
                        </Tabs.Tab>
                        <Tabs.Tab panelId="emergencyInfo">
                            Emergency message
                        </Tabs.Tab>
                        <Tabs.Tab panelId="species">Species</Tabs.Tab>
                        <Tabs.Tab panelId="calendar">Calendar</Tabs.Tab>
                        <Tabs.Tab panelId="openingHours">
                            Opening Hours
                        </Tabs.Tab>
                    </Tabs.List>
                    <Tabs.Panel id="AppointmentTypes">
                        <AppointmentTypes />
                    </Tabs.Panel>
                    <Tabs.Panel id="welcomeMessage">
                        <WelcomeMessage />
                    </Tabs.Panel>
                    <Tabs.Panel id="emergencyInfo">
                        <EmergencyInformation />
                    </Tabs.Panel>
                    <Tabs.Panel id="species">
                        <SpeciesTypes />
                    </Tabs.Panel>
                    <Tabs.Panel id="calendar">
                        <CalendarList />
                    </Tabs.Panel>
                    <Tabs.Panel id="openingHours">
                        <OpeningHours />
                    </Tabs.Panel>
                </Tabs>
            </Flex>
        </>
    );
};
