export interface AppointmentType {
    id: string;
    name: string;
    pmsName: string;
    duration: number;
    isActive: boolean;
    enabledImportantInformation: boolean;
    importantInformation: string;
    importantInformationSlate: string;
    hasAcceptCheckbox: boolean;
    sort: number;
    species: Species[];
    customerStatusTypeId: number;
}

export interface AppointmentMessage {
    id: string;
    title?: string;
    isActive: boolean;
    message: string;
    messageTypeId: number;
    messageTypeName: string;
    messageSlate: string;
}

export interface Species {
    id: string;
    name: string;
    pmsName: string;
    isActive: boolean;
    sort: number;
}

export enum Days {
    MONDAY = 1,
    TUESDAY = 2,
    WEDNESDAY = 3,
    THURSDAY = 4,
    FRIDAY = 5,
    SATURDAY = 6,
    SUNDAY = 7,
}

export const DayOptions: {
    label: string;
    value: Days;
}[] = [
    {
        label: 'Monday',
        value: Days.MONDAY,
    },
    {
        label: 'Tuesday',
        value: Days.TUESDAY,
    },
    {
        label: 'Wednesday',
        value: Days.WEDNESDAY,
    },
    {
        label: 'Thursday',
        value: Days.THURSDAY,
    },
    {
        label: 'Friday',
        value: Days.FRIDAY,
    },
    {
        label: 'Saturday',
        value: Days.SATURDAY,
    },
    {
        label: 'Sunday',
        value: Days.SUNDAY,
    },
];

export interface OpenPeriod {
    days: Days[];
    startTime: string;
    endTime: string;
}

export interface ClosedPeriod {
    name: string;
    start: string;
    end: string;
}

export interface Calendar {
    id: string;
    name: string;
    pmsName: string;
    appointmentTypes: AppointmentType[];
    speciesTypes: Species[];
    isActive: boolean;
    openPeriods: OpenPeriod[];
    closedPeriods: ClosedPeriod[];
}
