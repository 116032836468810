import React, { useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';

import {
    Badge,
    Button,
    Card,
    Flex,
    Form,
    FormControl,
    Heading,
    Modal,
    Select,
    Stack,
    Subheading,
    Tabs,
    Text,
    Textarea,
    TextInput,
    Tooltip,
} from '@contentful/f36-components';
import {
    DeleteIcon,
    InfoCircleIcon,
    PlusIcon,
    SearchIcon,
} from '@contentful/f36-icons';

import { ClinicSelect } from '../components/clinic-select';
import { DatetimePicker } from '../components/datetime-picker';
import { FormAppointmentDetail } from '../components/forms/form-appointment-detail';
import { PaginationWrapper } from '../components/pagination-wrapper';
import { TableList, TableSortPropsExternal } from '../components/table-list';
import { ENV } from '../environments';
import { useDebounce, useVetdeskMiddleware } from '../hooks';
import { ApiListResponse, Clinic } from '../models';
import { Appointment } from '../models/appointments';
import { formatDateToISOString } from '../utilities/date-helper';
import {
    AppointmentFetchListFilters,
    AppointmentFetchType,
    DEFAULT_FETCH_LIST_FILTERS,
    getQueryString,
} from '../utilities/fetch-filters';
import { GetAppointmentColumns } from '../utilities/table-columns';

const API_URL = `${ENV.middlewareBaseUrl}/api/v1/admin/appointment`;

export const PageAppointments = () => {
    const { fetchEntityList, createEntity } = useVetdeskMiddleware();
    const [appointments, setAppointments] = useState<
        ApiListResponse<Appointment> | undefined
    >(undefined);
    const [refresh, setRefresh] = useState(false);
    const [filters, setFilters] = useState<AppointmentFetchListFilters>({
        ...DEFAULT_FETCH_LIST_FILTERS,
        type: AppointmentFetchType.All,
        sortBy: 'bookingDate',
        sortOrderList: 'desc',
    });

    const [currentTab, setCurrentTab] = useState<AppointmentFetchType>(
        AppointmentFetchType.All
    );

    const [searchInput, setSearchInput] = useState<string>('');
    const debouncedSearchInput = useDebounce<string>(searchInput, 500);
    const [showForm, setShowForm] = useState(false); // detail form
    const [formData, setFormData] = useState(null);
    const [showCreateForm, setShowCreateForm] = useState(false); // create form

    const handlePageChange = (page: number) => {
        setFilters({
            ...filters,
            offset: page * filters.limit,
        });
    };

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchInput(event.target.value);
    };

    const handleUpsert = (formData?: any) => {
        setFormData(formData || null);
        setShowForm(true);
    };

    const fetchAppointments = () => {
        setAppointments(undefined); // to display skeleton when fetching
        fetchEntityList(`${API_URL}${getQueryString(filters)}`).then((res) => {
            const response = res as ApiListResponse<Appointment>;

            setAppointments(response);

            // rerender the detail form if opened
            if (showForm == true) {
                const selected = formData ? (formData as Appointment) : null;
                const updatedAppointment = selected
                    ? response?.items.find((x) => x.id == selected.id)
                    : null;
                updatedAppointment && setFormData(updatedAppointment as any);
            }
        });
    };

    useEffect(() => {
        fetchAppointments();
    }, [filters]);

    useEffect(() => {
        if (refresh) {
            fetchAppointments();
            setRefresh(false);
        }
    }, [refresh]);

    useEffect(() => {
        // if statement to avoid calling the fetch endpoint twice during mounting
        if (debouncedSearchInput !== filters.search) {
            setFilters({
                ...filters,
                search: debouncedSearchInput,
                offset: 0,
            });
        }
    }, [debouncedSearchInput]);

    useEffect(() => {
        // if statement to avoid calling the fetch endpoint twice during mounting
        if (currentTab !== filters.type) {
            setFilters({
                ...filters,
                type: currentTab,
                offset: 0,
            });
        }
    }, [currentTab]);

    const handleSort = (sort: TableSortPropsExternal) => {
        const updatedFilters = {
            ...filters,
            sortBy: sort.column,
            sortOrderList: sort.sortDirection,
        };

        if (sort.column === 'clinicName') {
            updatedFilters.sortBy = 'clinic.Name';
        }

        if (sort.column === 'date') {
            updatedFilters.sortBy = 'bookingDate';
        }

        setFilters(updatedFilters);
    };

    const renderTable = () => {
        return (
            <>
                <TableList<Appointment>
                    columns={GetAppointmentColumns}
                    items={appointments?.items}
                    onEdit={handleUpsert}
                    onSort={handleSort}
                />
                {appointments && (
                    <PaginationWrapper
                        onPageChange={handlePageChange}
                        filters={filters}
                        totalItems={appointments.total}
                    />
                )}
            </>
        );
    };

    const {
        handleSubmit,
        formState: { errors },
        reset,
        register,
        setValue,
        getValues,
        control,
        trigger,
    } = useForm();

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'pets',
        shouldUnregister: true,
    });

    const onSubmit = async (data: any) => {
        createEntity(API_URL, {
            ...data,
            title: 'Appointment',
            isAnonymous: true, // currently only anonymous appointment from admin
        }).then(
            () => {
                reset();
                setRefresh(true);
                setShowCreateForm(false);
            },
            (error) => console.warn('Oops', error)
        );
    };

    const getTodayDateAsString = () => {
        // Get today's date
        const today: Date = new Date();
        const year: number = today.getFullYear();
        let month: number | string = today.getMonth() + 1; // Months are zero-based
        let day: number | string = today.getDate();

        // Add leading zeros if needed
        if (month < 10) {
            month = '0' + month;
        }
        if (day < 10) {
            day = '0' + day;
        }
        // Format today's date as yyyy-mm-dd
        const todayString = `${year}-${month}-${day}`;

        return todayString;
    };

    const renderAppointmentFields = () => {
        register('date', {
            required: 'This is required field',
        });
        register('clinicId', {
            required: 'This is required field',
        });
        return (
            <Card>
                <FormControl>
                    <FormControl.Label>Date and time</FormControl.Label>
                    <DatetimePicker
                        fromDate={new Date()}
                        onSelect={(date: Date) => {
                            setValue('date', formatDateToISOString(date));
                        }}
                    />
                    {errors.date && (
                        <Text fontColor="red500">
                            {errors.date.message?.toString()}
                        </Text>
                    )}
                </FormControl>
                <FormControl>
                    <FormControl.Label>Clinic</FormControl.Label>
                    <ClinicSelect
                        value={
                            getValues('clinicId') ? getValues('clinicId') : ''
                        }
                        onSelect={(clinic: Clinic) => {
                            setValue('clinicId', clinic.id);
                            trigger('clinicId');
                        }}
                    />
                    {errors.clinicId && (
                        <Text fontColor="red500">
                            {errors.clinicId.message?.toString()}
                        </Text>
                    )}
                </FormControl>
                <FormControl>
                    <FormControl.Label>
                        First Name <Text fontColor="red500">*</Text>
                    </FormControl.Label>
                    <TextInput
                        {...register(`firstName`, {
                            required: 'This is required field',
                        })}
                    />
                    {errors.firstName && (
                        <Text fontColor="red500">
                            {errors.firstName.message?.toString()}
                        </Text>
                    )}
                </FormControl>
                <FormControl>
                    <FormControl.Label>
                        Last Name <Text fontColor="red500">*</Text>
                    </FormControl.Label>
                    <TextInput
                        {...register(`lastName`, {
                            required: 'This is required field',
                        })}
                    />
                    {errors.lastName && (
                        <Text fontColor="red500">
                            {errors.lastName.message?.toString()}
                        </Text>
                    )}
                </FormControl>
                <FormControl>
                    <FormControl.Label>
                        Email <Text fontColor="red500">*</Text>
                    </FormControl.Label>
                    <TextInput
                        {...register(`email`, {
                            required: 'This is required field',
                            pattern: {
                                value: /\S+@\S+\.\S+/,
                                message: 'Invalid email',
                            },
                        })}
                    />
                    {errors.email && (
                        <Text fontColor="red500">
                            {errors.email.message?.toString()}
                        </Text>
                    )}
                </FormControl>
                <FormControl>
                    <FormControl.Label>
                        Phone <Text fontColor="red500">*</Text>
                    </FormControl.Label>
                    <TextInput
                        {...register(`phone`, {
                            required: 'This is required field',
                            pattern: {
                                value: /^\d{0,15}$/,
                                message: 'Invalid phone number',
                            },
                        })}
                    />
                    {errors.phone && (
                        <Text fontColor="red500">
                            {errors.phone.message?.toString()}
                        </Text>
                    )}
                </FormControl>

                <FormControl>
                    <FormControl.Label>
                        Comment <Text fontColor="red500">*</Text>
                    </FormControl.Label>
                    <Textarea
                        rows={2}
                        {...register(`comment`, {
                            required: 'This is required field',
                        })}
                    />
                    {errors.comment && (
                        <Text fontColor="red500">
                            {errors.comment.message?.toString()}
                        </Text>
                    )}
                </FormControl>
            </Card>
        );
    };

    const renderPetsFields = () => {
        const petErrors = errors.pets as any;
        return (
            <>
                {fields.map((field, index) => {
                    const thisPetError = petErrors && petErrors[index];

                    // to focus on name when the button is clicked
                    const registerPetName = register(`pets.${index}.name`, {
                        required: 'This is required field',
                    });

                    const registerPetType = register(`pets.${index}.type`, {
                        required: 'This is required field',
                    });

                    const registerPetDOB = register(
                        `pets.${index}.dateOfBirth`,
                        {
                            required: 'This is required field',
                        }
                    );

                    const registerPetGender = register(`pets.${index}.gender`, {
                        required: 'This is required field',
                    });

                    return (
                        <Card key={field.id} style={{ marginBottom: 10 }}>
                            <Text fontSize="fontSizeXl">Pet {index + 1}</Text>
                            <Flex
                                flexDirection="row"
                                gap="spacingS"
                                justifyContent="center"
                            >
                                <div style={{ width: '50%' }}>
                                    <FormControl>
                                        <FormControl.Label>
                                            Name{' '}
                                            <Text fontColor="red500">*</Text>
                                        </FormControl.Label>
                                        <TextInput
                                            name={registerPetName.name}
                                            onChange={registerPetName.onChange}
                                            onBlur={registerPetName.onBlur}
                                            ref={registerPetName.ref}
                                        />
                                        {thisPetError && thisPetError.name && (
                                            <Text fontColor="red500">
                                                {thisPetError.name.message}
                                            </Text>
                                        )}
                                    </FormControl>
                                    <FormControl>
                                        <FormControl.Label>
                                            Gender{' '}
                                            <Text fontColor="red500">*</Text>
                                        </FormControl.Label>
                                        <Select
                                            name={registerPetGender.name}
                                            onChange={(e) => {
                                                registerPetGender.onChange(e);
                                                trigger(registerPetGender.name);
                                            }}
                                            onBlur={registerPetGender.onBlur}
                                            ref={registerPetGender.ref}
                                            defaultValue=""
                                        >
                                            <Select.Option value="" isDisabled>
                                                Please select...
                                            </Select.Option>
                                            <Select.Option value="unknown">
                                                Unknown
                                            </Select.Option>
                                            <Select.Option value="male">
                                                Male
                                            </Select.Option>
                                            <Select.Option value="female">
                                                Female
                                            </Select.Option>
                                        </Select>
                                        {thisPetError &&
                                            thisPetError.gender && (
                                                <Text fontColor="red500">
                                                    {
                                                        thisPetError.gender
                                                            .message
                                                    }
                                                </Text>
                                            )}
                                    </FormControl>

                                    <FormControl>
                                        <FormControl.Label>
                                            Pet Type{' '}
                                            <Text fontColor="red500">*</Text>
                                        </FormControl.Label>
                                        <Select
                                            name={registerPetType.name}
                                            onChange={(e) => {
                                                registerPetType.onChange(e);
                                                trigger(registerPetType.name);
                                            }}
                                            onBlur={registerPetType.onBlur}
                                            ref={registerPetType.ref}
                                            defaultValue=""
                                        >
                                            <Select.Option value="" isDisabled>
                                                Please select...
                                            </Select.Option>
                                            <Select.Option value="cat">
                                                Cat
                                            </Select.Option>
                                            <Select.Option value="dog">
                                                Dog
                                            </Select.Option>
                                            <Select.Option value="other">
                                                Other
                                            </Select.Option>
                                        </Select>
                                        {thisPetError && thisPetError.type && (
                                            <Text fontColor="red500">
                                                {thisPetError.type.message}
                                            </Text>
                                        )}
                                    </FormControl>
                                </div>
                                <div style={{ width: '50%' }}>
                                    <FormControl>
                                        <FormControl.Label>
                                            Breed{' '}
                                            <Text fontColor="red500">*</Text>
                                        </FormControl.Label>
                                        <TextInput
                                            {...register(
                                                `pets.${index}.breed` as const,
                                                {
                                                    required:
                                                        'This is required field',
                                                }
                                            )}
                                        />
                                        {thisPetError && thisPetError.breed && (
                                            <Text fontColor="red500">
                                                {thisPetError.breed.message}
                                            </Text>
                                        )}
                                    </FormControl>

                                    <FormControl>
                                        <FormControl.Label>
                                            Date of birth (approx if unknown){' '}
                                            <Text fontColor="red500">*</Text>
                                        </FormControl.Label>
                                        {/*
                                        This date picker have some issue with react hook form registeration
                                        <Datepicker
                                            fromDate={new Date(1900, 0, 1)}
                                            toDate={new Date()}
                                            selected={getValues(
                                                registerPetDOB.name
                                            )}
                                            inputProps={{
                                                name: registerPetDOB.name,
                                                onBlur: registerPetDOB.onBlur,
                                                //itemRef: registerPetDOB.ref,
                                                onChange: (e) => {
                                                    registerPetDOB.onChange(e);
                                                    trigger(
                                                        registerPetDOB.name
                                                    );
                                                },
                                            }}
                                            onSelect={(date) => {
                                                // dateOfBirth.onChange({
                                                //     target: date,
                                                // });
                                            }}
                                        />  */}
                                        <input
                                            style={{
                                                width: '100%',
                                                height: '40px',
                                                borderRadius: '5px',
                                                border: '1px solid #CFD9E0',
                                                padding: 10,
                                            }}
                                            type="date"
                                            max={getTodayDateAsString()}
                                            name={registerPetDOB.name}
                                            onChange={(e) => {
                                                registerPetDOB.onChange(e);
                                                trigger(registerPetDOB.name);
                                            }}
                                            onBlur={registerPetDOB.onBlur}
                                            ref={registerPetDOB.ref}
                                        />
                                        {thisPetError &&
                                            thisPetError.dateOfBirth && (
                                                <Text fontColor="red500">
                                                    {
                                                        thisPetError.dateOfBirth
                                                            .message
                                                    }
                                                </Text>
                                            )}
                                    </FormControl>

                                    {getValues(registerPetType.name) &&
                                    getValues(registerPetType.name) ==
                                        'other' ? (
                                        <FormControl>
                                            <FormControl.Label>
                                                Specify Pet Type (if Other is
                                                select above){' '}
                                                <Text fontColor="red500">
                                                    *
                                                </Text>
                                            </FormControl.Label>
                                            <TextInput
                                                {...register(
                                                    `pets.${index}.otherPetType` as const,
                                                    {
                                                        required:
                                                            'This is required field',
                                                    }
                                                )}
                                            />
                                            {thisPetError &&
                                                thisPetError.otherPetType && (
                                                    <Text fontColor="red500">
                                                        {
                                                            thisPetError
                                                                .otherPetType
                                                                .message
                                                        }
                                                    </Text>
                                                )}
                                        </FormControl>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                            </Flex>

                            <Button
                                startIcon={<DeleteIcon />}
                                size="small"
                                onClick={() => remove(index)}
                            >
                                Remove
                            </Button>
                        </Card>
                    );
                })}
            </>
        );
    };

    const renderCreateForm = () => (
        <Modal
            isShown={showCreateForm}
            size="1200px"
            onClose={() => {
                setShowCreateForm(false);
                remove();
                reset();
            }}
            shouldCloseOnOverlayClick={false}
        >
            {() => (
                <>
                    <Modal.Header
                        title="Complete the form below to create an appointment."
                        onClose={() => {
                            setShowCreateForm(false);
                            remove();
                            reset();
                        }}
                    />
                    <Modal.Content>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <Flex
                                flexDirection="row"
                                gap="spacingS"
                                justifyContent="center"
                            >
                                <div style={{ width: '45%' }}>
                                    <Subheading>Appointment details</Subheading>
                                    {renderAppointmentFields()}
                                </div>
                                <div style={{ width: '55%' }}>
                                    <Subheading>Pet details</Subheading>

                                    {renderPetsFields()}

                                    <Button
                                        startIcon={<PlusIcon />}
                                        variant="secondary"
                                        size="small"
                                        className="addPetButton"
                                        onClick={() => {
                                            append({});
                                        }}
                                    >
                                        Add a pet
                                    </Button>
                                </div>
                            </Flex>
                        </Form>
                    </Modal.Content>
                    <Modal.Controls>
                        <Button
                            size="small"
                            variant="transparent"
                            onClick={() => {
                                setShowCreateForm(false);
                                remove();
                                reset();
                            }}
                        >
                            Close
                        </Button>
                        <Button
                            size="small"
                            variant="primary"
                            onClick={() => handleSubmit(onSubmit)()}
                        >
                            Submit
                        </Button>
                    </Modal.Controls>
                </>
            )}
        </Modal>
    );

    const renderStatsBadge = (type: AppointmentFetchType) =>
        appointments &&
        type == currentTab && (
            <Badge style={{ marginLeft: 10 }}>{appointments.total}</Badge>
        );

    return (
        <>
            <Stack justifyContent="space-between">
                <Heading>
                    Appointments{' '}
                    <Tooltip
                        placement="top"
                        content="'Completed' and 'Cancelled' appointments will be moved to the 'Archived' Tab after 7 days from the appointment date."
                    >
                        <InfoCircleIcon
                            id="tooltip_1"
                            style={{ verticalAlign: 'middle' }}
                            size="medium"
                        />
                    </Tooltip>
                </Heading>
                <div>
                    <Button
                        startIcon={<PlusIcon />}
                        variant="primary"
                        size="medium"
                        className="addButton"
                        onClick={() => {
                            setFormData(null);
                            setShowCreateForm(true);
                        }}
                    >
                        Create
                    </Button>
                </div>
            </Stack>
            <Flex className="spacingTop" flexDirection="column">
                <TextInput
                    onChange={handleSearch}
                    aria-label="Content type name"
                    id="searchString"
                    placeholder="Search..."
                    icon={<SearchIcon />}
                />
                <Tabs
                    currentTab={currentTab}
                    onTabChange={(e: string | AppointmentFetchType) =>
                        setCurrentTab(e as AppointmentFetchType)
                    }
                >
                    <Tabs.List>
                        <Tabs.Tab panelId={AppointmentFetchType.All}>
                            All
                            {renderStatsBadge(AppointmentFetchType.All)}
                        </Tabs.Tab>
                        <Tabs.Tab panelId={AppointmentFetchType.New}>
                            New / Pending{' '}
                            {renderStatsBadge(AppointmentFetchType.New)}
                        </Tabs.Tab>
                        <Tabs.Tab panelId={AppointmentFetchType.Confirmed}>
                            Confirmed
                            {renderStatsBadge(AppointmentFetchType.Confirmed)}
                        </Tabs.Tab>
                        <Tabs.Tab panelId={AppointmentFetchType.Declined}>
                            Cancelled
                            {renderStatsBadge(AppointmentFetchType.Declined)}
                        </Tabs.Tab>
                        <Tabs.Tab panelId={AppointmentFetchType.Archived}>
                            Archived
                            {renderStatsBadge(AppointmentFetchType.Archived)}
                        </Tabs.Tab>
                    </Tabs.List>
                    <Tabs.Panel id={AppointmentFetchType.All}>
                        {renderTable()}
                    </Tabs.Panel>
                    <Tabs.Panel id={AppointmentFetchType.New}>
                        {renderTable()}
                    </Tabs.Panel>
                    <Tabs.Panel id={AppointmentFetchType.Confirmed}>
                        {renderTable()}
                    </Tabs.Panel>
                    <Tabs.Panel id={AppointmentFetchType.Declined}>
                        {renderTable()}
                    </Tabs.Panel>
                    <Tabs.Panel id={AppointmentFetchType.Archived}>
                        {renderTable()}
                    </Tabs.Panel>
                </Tabs>
            </Flex>
            {showForm && (
                <FormAppointmentDetail
                    formData={formData}
                    setRefresh={setRefresh}
                    setVisibility={setShowForm}
                    isVisible={showForm}
                />
            )}
            {showCreateForm && renderCreateForm()}
        </>
    );
};
