export const CATEGORY_COLUMNS = [
    { label: 'Name', type: 'string', field: 'name' },
    { label: 'Status', type: 'badge', field: 'status', width: 160 },
];

export const PRODUCT_COLUMNS = [
    { label: '', type: 'images', field: 'images', width: 110 },
    { label: 'Name', type: 'string', field: 'name' },
    { label: 'Category', type: 'list', field: 'categories', extras: 'name' },
    { label: 'Supplier', type: 'string', field: 'supplierName' },
    { label: 'SKU', type: 'string', field: 'supplierSku' },
    { label: 'Brands', type: 'list', field: 'brands', extras: 'name' },
    // hidden for now
    // { label: 'Type', type: 'list', field: 'petTypes', extras: 'name' },
    { label: 'Supplier Price', type: 'currency', field: 'supplierPrice' },
    { label: 'Selling Price', type: 'currency', field: 'price' },
    { label: 'Discounted Price', type: 'currency', field: 'discountedPrice' },
    { label: 'In Stock', type: 'badgeBoolean', field: 'inStock' },
    { label: 'Status', type: 'badge', field: 'status' },
];

export const SUPPLIER_PRODUCT_COLUMNS = [
    { label: '', type: 'images', field: 'images', width: 150 },
    { label: 'Name', type: 'string', field: 'title' },
    { label: 'SKU', type: 'string', field: 'sku' },
    {
        label: 'Stock Qty',
        type: 'number',
        field: 'warehouseStockQuantity',
        width: 160,
    },
    { label: 'Price', type: 'currency', field: 'price', width: 160 },
];

export const COUPON_COLUMNS = [
    { label: 'Code', type: 'string', field: 'code' },
    { label: 'Value', type: 'string', field: 'value' },
    { label: 'Type', type: 'string', field: 'type' },
    { label: 'Description', type: 'string', field: 'description' },
    { label: 'From', type: 'dateOnly', field: 'validFromDate' },
    { label: 'To', type: 'dateOnly', field: 'validToDate' },
    { label: 'Max Redemptions', type: 'number', field: 'maxRedemptions' },
    { label: 'Redemption Count', type: 'number', field: 'redemptionCount' },
    { label: 'Status', type: 'badgeCoupon', field: 'status', width: 160 },
    //default to order for now
    // { label: 'For Order', type: 'boolean', field: 'validForOrder' },
    // {
    //     label: 'For Subscription',
    //     type: 'boolean',
    //     field: 'validForSubscription',
    // },
];

export const ORDER_COLUMNS = [
    { label: 'Order #', type: 'string', field: 'number' },
    { label: 'Customer', type: 'string', field: 'username' },
    { label: '# Products', type: 'number', field: 'productCount' },
    { label: 'Amount', type: 'currency', field: 'totalAmount' },
    {
        label: 'Amount (after coupon applied)',
        type: 'currency',
        field: 'totalAfterCouponRedemption',
    },
    { label: 'Coupon code', type: 'string', field: 'coupon' },
    { label: 'Created Date', type: 'date', field: 'createdDate' },
    { label: 'Order Status', type: 'badgeOrder', field: 'status' },
    {
        label: 'Tracking Status',
        type: 'badgeOrderTracking',
        field: 'courierTracking[0].status',
    },
];

export const FUNDRAISER_ORDER_COLUMNS = [
    { label: 'Order #', type: 'string', field: 'number' },
    { label: 'Customer', type: 'string', field: 'username' },
    { label: '# Products', type: 'number', field: 'productCount' },
    { label: 'Amount', type: 'currency', field: 'totalAmount' },
    { label: 'Event Name', type: 'string', field: 'eventName' },
    { label: 'Created Date', type: 'date', field: 'createdDate' },
    { label: 'Status', type: 'badgeOrder', field: 'status' },
];

export const SUBSCRIPTION_COLUMNS = [
    { label: '#', type: 'string', field: 'number' },
    { label: 'Name', type: 'string', field: 'name' },
    { label: 'Customer', type: 'string', field: 'username' },
    { label: '# Products', type: 'number', field: 'productCount' },
    { label: 'Amount', type: 'currency', field: 'totalAmount' },
    { label: 'Created Date', type: 'date', field: 'createdDate' },
    { label: 'Next', type: 'dateOnly', field: 'nextPaymentDate' },
    {
        label: 'Frequency',
        type: 'badgeSubscriptionFrequency',
        field: 'interval',
    },
    { label: 'Interval', type: 'string', field: 'intervalCount' },
    { label: 'Status', type: 'badgeSubscription', field: 'status' },
];

export const ONEOFF_INVOICES_COLUMNS = [
    { label: 'Transaction #', type: 'string', field: 'number' },
    { label: 'Reference', type: 'string', field: 'userReference' },
    { label: 'Customer', type: 'string', field: 'username' },
    {
        label: 'Customer Stripe Id',
        type: 'string',
        field: 'userStripeIdentifier',
    },
    { label: 'Amount', type: 'currency', field: 'gatewayAmountReceived' },
    { label: 'Payment Stripe Id', type: 'string', field: 'gatewayReference' },
    { label: 'Created Date', type: 'date', field: 'createdDate' },
    { label: 'Status', type: 'badgeSubscription', field: 'status' },
];

export const ANONYMOUS_ONEOFF_INVOICES_COLUMNS = [
    { label: 'Transaction #', type: 'string', field: 'number' },
    { label: 'Reference', type: 'string', field: 'userReference' },
    { label: 'Amount', type: 'currency', field: 'gatewayAmountReceived' },
    { label: 'Payment Stripe Id', type: 'string', field: 'gatewayReference' },
    { label: 'Created Date', type: 'date', field: 'createdDate' },
    { label: 'Status', type: 'badgeSubscription', field: 'status' },
];

export const FUNDRAISER_COLUMNS = [
    { label: 'Name', type: 'string', field: 'name' },
    { label: 'Active From', type: 'date', field: 'activeFrom' },
    { label: 'Active Until', type: 'date', field: 'activeUntil' },
    { label: '# Products', type: 'number', field: 'productCount' },
    { label: 'Pay On Pickup', type: 'boolean', field: 'payOnPickup' },
];

export const PHOTO_COMPETITION_EVENT_COLUMNS = [
    { label: 'Title', type: 'string', field: 'title' },
    { label: 'Description', type: 'string', field: 'description' },
    { label: 'Entries', type: 'number', field: 'entriesCount' },
    { label: 'Prize', type: 'string', field: 'prize' },
    { label: 'Start Date', type: 'date', field: 'startDate' },
    { label: 'End Date', type: 'date', field: 'endDate' },
    {
        label: 'Status',
        type: 'badgePhotoCompetition',
        field: 'status',
        width: 160,
    },
];

export const PHOTO_COMPETITION_ENTRY_COLUMNS = [
    { label: '', type: 'image', field: 'imageUrl', width: 110 },
    { label: 'Title', type: 'string', field: 'title' },
    { label: 'Vote Count', type: 'number', field: 'voteCount' },
    { label: 'Username', type: 'string', field: 'username' },
    { label: 'Pet Name', type: 'string', field: 'petName' },
    { label: 'Entry Date', type: 'date', field: 'entryDate' },
    {
        label: 'Is Reported',
        type: 'reportedEntry',
        field: 'reportedByCustomer',
    },
    { label: 'Share On', type: 'share', field: 'allowSocialSharing' },
];

export const CUSTOMER_COLUMNS = [
    { label: 'First Name', type: 'string', field: 'firstName' },
    { label: 'Last Name', type: 'string', field: 'lastName' },
    { label: 'Email', type: 'string', field: 'email' },
    { label: 'Stripe #', type: 'string', field: 'stripeIdentifier' },
    { label: 'phone', type: 'string', field: 'phone' },
    { label: 'Status', type: 'badgeCustomer', field: 'statusId', width: 160 },
];

export const GetAppointmentColumns = [
    // {
    //     label: 'Title',
    //     type: 'string',
    //     field: 'title',
    // },
    {
        label: 'First name',
        type: 'string',
        field: 'firstName',
    },
    {
        label: 'Last name',
        type: 'string',
        field: 'lastName',
    },
    {
        label: 'Phone',
        type: 'number',
        field: 'phone',
        width: 120,
    },
    {
        label: 'Email',
        type: 'string',
        field: 'email',
    },
    {
        label: 'Clinic',
        type: 'string',
        field: 'clinicName',
        isSortable: true,
    },
    { label: 'Date and time', type: 'date', field: 'date', isSortable: true },
    {
        label: 'Status',
        type: 'badgeAppointment',
        field: 'status',
        isSortable: true,
    },
];

export const NOTIFICATION_COLUMNS = [
    { label: 'Title', type: 'string', field: 'title' },
    { label: 'Body', type: 'string', field: 'body' },
    { label: 'Scheduled Date', type: 'date', field: 'scheduledDate' },
    // { label: 'Processed On', type: 'date', field: 'processedOn' },
    { label: 'Origin', type: 'string', field: 'origin' },
    { label: 'Status', type: 'badgeNotification', field: 'status', width: 160 },
];

export const OPENING_HOUR_COLUMNS = [
    { label: 'Name', type: 'string', field: 'name' },
    { label: 'PMS Name', type: 'string', field: 'pmsName' },
    {
        label: 'Status',
        type: 'badgeActiveInactive',
        field: 'isActive',
        width: 160,
    },
];
